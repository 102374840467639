import React, { Component } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import './../../../styles/components/linkline.scss';
import TabBar from '../tabmenu/TabBar';

import { withRouter } from 'react-router-dom';
import { notify } from '../../../utils';
import { getGuessChoice } from '../../../actions';
import { connect } from 'react-redux';
import { TYPES } from '../../../actions';
import _ from 'lodash';
import Func from '../../../utils/Func';

class LinkLineAccountSuccess extends Component {
  render() {
    return (
      <div className="container-fluid" style={{ maxWidth: 1200 }} id="user-completion">
        <h1 className=" d-flex  justify-content-center" style={{ fontSize: '26px', color: 'black' }}>
          店舗連携申請が完了
        </h1>
        <div className="w-100 card-completion text-dark mt-70">
          <div className="card-completion-main w-100 d-flex flex-column text-center justify-content-center pt-40 pb-70" style={{ border: '2px solid #333' }}>
            <div>
              <BsCheckCircle className="check-icon" fontSize={36} />
            </div>
            <div className="card-completion-content d-flex flex-column justify-content-center">
              <h4 className="my-30" style={{ fontSize: '20px' }}>
                店舗連携申請が完了しました。
              </h4>
              <p className="m-0 PC-version">
                店舗連携申請完了LINEメッセージをお送りましたので、<br></br>内容をご確認ください。
              </p>
              <p className="m-0 mobile-version">
                店舗連携申請完了LINEメッセージをお送りましたので、内容をご確認ください。
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { staffReducer } = state;
  return {
    actionNotify: staffReducer.actionNotify,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getGuessChoice,
  })(LinkLineAccountSuccess)
);
