import React, { Component } from 'react';
import InputForm from '../atoms/inputForm';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import _ from 'lodash';
import { TYPES, getUserInfo, salonUpdateWorkingTime, salonUpdateSettings } from '../../../actions';

import { LANGUAGES, getTranslate, checkValidate, notify } from '../../../utils';

class StoreInforSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isFemaleStore: false,
      userInfo: props.userInfo,

      formMode: 'update',
      form: {
        working_time: '',
      },
      formIsValid: false,
      msg: {},
      userInfo: null,

      actionNotify: {
        time: +new Date(),
      },
    };
  }

  componentDidMount() {
    this.props.getUserInfo();
  }

  UNSAFE_componentWillReceiveProps(props) {
    console.log("Unsafe component will receive props: ", props);
    if (JSON.stringify(this.state.userInfo) != JSON.stringify(props.userInfo)) {
      this.setState({ 
        userInfo: props.userInfo, 
        form: { working_time: _.get(props, 'userInfo.working_time', '') },
        isFemaleStore: _.get(props, 'userInfo.salon_gender', false) === "female" ? true : false,
      });
    }

    if (!_.isEmpty(props.actionNotify) && props.actionNotify.time > this.state.actionNotify.time) {
      switch (props.actionNotify.type) {
        case TYPES.ACTION_SALON_UPDATE_SETTINGS_SUCCESS:
          this.props.getUserInfo();
          setTimeout(() => {
            notify('店舗情報設定が完了しました。', 'success');
          }, 200);

          break;

        default:
          break;
      }
      this.setState({
        actionNotify: props.actionNotify,
      });
    }
  }

  onChangeFormField = (e, field) => {
    const value = e.target.value;
    let newForm = {
      ...this.state.form,
      [field]: value,
    };

    this.setState({
      form: newForm,
      msg: {},
    });

    document.getElementById('txtWorkingTime').setCustomValidity('');

    if (this.form) this.form.classList.remove('was-validated');
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    this.form = form;

    let msg = {};
    if (!/\b\d{1,2}h\d{2}-\d{1,2}h\d{2}\b/.test(this.state.form.working_time)) {
      msg.working_time = getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_WORKING_TIME_WITH_FORMAT_START_END);
    }

    let formIsValid = _.isEmpty(msg);

    this.setState({
      msg,
      formIsValid,
    });

    document.getElementById('txtWorkingTime').setCustomValidity(_.get(msg, 'working_time', ''));

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      console.log('form is invalid');
    } else {
      console.log('form is valid');
      // this.props.salonUpdateWorkingTime({
      //   working_time: this.state.form.working_time
      // });
      this.props.salonUpdateSettings({
        gender: this.state.isFemaleStore ? 'female' : 'others',
        working_time: this.state.form.working_time
      });
    }

    form.classList.add('was-validated');
  };

  handleCheckboxChange = (e) => {
    this.setState({
      isFemaleStore: e.target.checked,
    });
  };

  render() {
    const { error, isFemaleStore } = this.state;

    return (
      <div className="text-center">
        <h1 style={{ color: 'black', fontSize: '26px', fontWeight: 'bold', marginBottom: '20px' }}>店舗情報設定</h1>
        <h2 style={{
          color: 'black',
          fontSize: '16px',
          marginBottom: '20px',
          fontWeight: 'bold',
          width: '90%',
          maxWidth: 500,
          textAlign: 'left',
          margin: 'auto',
          paddingBottom: '10px',
        }}>
          <span style={{ fontSize: '20px', }}>店舗情報設定</span>
          <br />
          営業時間を開始時間-終了時間のフォーマットで
          <br />
          入力してください（例:8h00-23h00）
        </h2>

        <div className="d-flex justify-content-center">
          <form noValidate autoComplete="off" className={'needs-validation'} onSubmit={this.handleSubmit} style={{ width: '90%', maxWidth: 500 }}>
            <div className="mx-auto" style={{ width: '100%' }}>
              <InputForm
                style={{ width: '100%' }}
                type={'input'}
                required="true"
                id="txtWorkingTime"
                msg={_.get(this.state, 'msg.working_time', '')}
                value={this.state.form.working_time}
                onChange={(e) => this.onChangeFormField(e, 'working_time')}
              />

              {error && <div style={{ color: 'red' }}>{error}</div>}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <input
                 style={{
                  // appearance: 'none',
                  width: '20px',
                  height: '20px',
                  marginRight: '8px',
                  cursor: 'pointer',
                  // border: '2px solid black',
                  // backgroundColor: isChecked ? 'blue' : 'white',

                }}
                  type="checkbox"
                  checked={isFemaleStore}
                  onChange={this.handleCheckboxChange}
                />
                <span
                  style={{ 
                    color: 'black', 
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    userSelect: 'none',
                  }}
                  onClick={() => this.setState({ isFemaleStore: !isFemaleStore })}
                >
                  女性専用サロンとする
                </span>
              </div>
              <button style={{ width: '100%', fontWeight: 'bold',fontSize:'22px' }} type="submit" className="btn btn-success btn-block btn-round">
                設定
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { staffReducer, userReducer } = state;

  return {
    actionNotify: staffReducer.actionNotify,
    userInfo: userReducer.userInfo,
  };
}

export default connect(mapStateToProps, { 
  getUserInfo, 
  salonUpdateWorkingTime,
  salonUpdateSettings
})(StoreInforSetting);
