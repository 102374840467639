import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { staffReducer } from './staffReducer';
import { salonReducer } from './salonReducer';

const reducers = combineReducers({
  userReducer,
  staffReducer,
  salonReducer,
});

export default reducers;
