import axios from 'axios';
import _ from 'lodash';

const get = (url, data = {}, headers = null) => {
  const accessToken = window.localStorage.getItem('salon_token');
  if (accessToken) axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  return axios
    .get(url, {
      timeout: 180000,
    })
    .catch((error) => {
      if (!error.response) {
        error.response = {
          data: {
            error_code: 102,
            message: 'No response',
          },
        };
      }

      if (_.get(error, 'response.status') == 401) {
        console.log('error', new Date().toLocaleString(), error);
        localStorage.removeItem('salon_token');
        window.location.href = '/login';
      }

      throw error;
    })
    .then((res) => {
      let newToken = _.get(res, ['headers', 'x-auth-token']);
      if (newToken) {
        window.localStorage.setItem('salon_token', newToken);
        console.log('update salon_token', new Date().toLocaleString());
      }
      return res;
    });
};

const post = (url, data = {}, headers = null) => {
  const accessToken = window.localStorage.getItem('salon_token');
  if (accessToken) axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  return axios
    .post(url, data, {
      timeout: 1800000,
    })
    .catch((error) => {
      if (!error.response) {
        error.response = {
          data: {
            error_code: 102,
            message: 'No response',
          },
        };
      }

      if (_.get(error, 'response.status') == 401) {
        console.log('error', new Date().toLocaleString(), error);

        localStorage.removeItem('salon_token');
        window.location.href = '/login';
      }

      throw error;
    })
    .then((res) => {
      let newToken = _.get(res, ['headers', 'x-auth-token']);
      if (newToken) {
        window.localStorage.setItem('salon_token', newToken);
        console.log('update salon_token', new Date().toLocaleString());
      }
      return res;
    });
};

const postForm = (url, data = {}, headers = null) => {
  const accessToken = window.localStorage.getItem('salon_token');
  if (accessToken) axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  return axios
    .post(url, data, {
      timeout: 1800000,
      mimeType: 'multipart/form-data',
    })
    .catch((error) => {
      if (!error.response) {
        error.response = {
          data: {
            error_code: 102,
            message: 'No response',
          },
        };
      }

      if (_.get(error, 'response.status') == 401) {
        console.log('error', new Date().toLocaleString(), error);

        localStorage.removeItem('salon_token');
        window.location.href = '/login';
      }

      throw error;
    })
    .then((res) => {
      let newToken = _.get(res, ['headers', 'x-auth-token']);
      if (newToken) {
        window.localStorage.setItem('salon_token', newToken);
        console.log('update salon_token', new Date().toLocaleString());
      }
      return res;
    });
};

export const API = { get, post, postForm };
