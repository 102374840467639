import { TYPES } from '../actions';
import _ from 'lodash';

const initialState = {
  userInfo: {},
  token: '',
  error_code: 0,
  actionNotify: {},
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ACTION_LOGIN_SUCCESS:
      window.localStorage.setItem('salon_token', action.payload.token);

      return {
        ...state,
        token: action.payload.token,
        error_code: 0,
        userInfo: action.payload.userInfo,
        actionNotify: {
          time: +new Date(),
          type: action.type,
        },
      };

    case TYPES.ACTION_LOGIN_FAIL:
      return {
        ...state,
        token: '',
        error_code: action.payload.error_code,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          error_code: action.payload.error_code,
        },
      };
    case TYPES.ACTION_USER_GET_USER_INFO_SUCCESS:
      return {
        ...state,
        token: localStorage.getItem('salon_token'),
        userInfo: action.payload.data,
        actionNotify: {
          time: +new Date(),
          type: action.type,
        },
      };
    case TYPES.ACTION_CALL_API_FAIL:
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          error_code: _.get(action.payload, 'message.error_code') ||  _.get(action.payload, 'data.error_code'),
        },
      };

    default:
      return state;
  }
};
