export const TYPES = {
  ACTION_CALL_API_FAIL: 'ACTION_CALL_API_FAIL',

  ACTION_LOGIN_SUCCESS: 'ACTION_LOGIN_SUCCESS',
  ACTION_LOGIN_FAIL: 'ACTION_LOGIN_FAIL',

  ACTION_USER_GET_USER_INFO_SUCCESS: 'ACTION_USER_GET_USER_INFO_SUCCESS',
  ACTION_GET_USER_INFO_FAIL: 'ACTION_GET_USER_INFO_FAIL',

  // salon
  ACTION_SALON_GET_LIST_SUCCESS: 'ACTION_SALON_GET_LIST_SUCCESS',
  ACTION_SALON_SAVE_SUCCESS: 'ACTION_SALON_SAVE_SUCCESS',
  ACTION_SALON_LINK_LINE_ACCOUNT_SUCCESS: 'ACTION_SALON_LINK_LINE_ACCOUNT_SUCCESS',
  ACTION_GET_GUESS_SALON_SUCCESS: 'ACTION_GET_GUESS_SALON_SUCCESS',
  ACTION_SALON_UPDATE_WORKING_TIME_SUCCESS: 'ACTION_SALON_UPDATE_WORKING_TIME_SUCCESS',
  ACTION_SALON_UPDATE_SETTINGS_SUCCESS: 'ACTION_SALON_UPDATE_SETTINGS_SUCCESS',


  // staff
  ACTION_STAFF_GET_LIST_SUCCESS: 'ACTION_STAFF_GET_LIST_SUCCESS',
  ACTION_STAFF_UPDATE_INFO_SUCCESS: 'ACTION_STAFF_UPDATE_INFO_SUCCESS',
  ACTION_STAFF_CHANGE_PASSWORD_SUCCESS: 'ACTION_STAFF_CHANGE_PASSWORD_SUCCESS',
  ACTION_STAFF_FORGET_PASSWORD_SUCCESS: 'ACTION_STAFF_FORGET_PASSWORD_SUCCESS',
  ACTION_STAFF_RESET_PASSWORD_SUCCESS: 'ACTION_STAFF_RESET_PASSWORD_SUCCESS',

  //create guess user
  ACTION_CREATE_GUESS_USER_SUCCESSFULLY: 'ACTION_CREATE_GUESS_USER_SUCCESSFULLY',
  ACTION_CREATE_GUESS_USER_FAIL: 'ACTION_CREATE_GUESS_USER_FAIL',

  //get shift
  ACTION_GET_SHIFT_SUCCESSFULLY: 'ACTION_GET_SHIFT_SUCCESSFULLY',
  ACTION_GET_SHIFT_FAIL: 'ACTION_GET_SHIFT_FAIL',
  ACTION_GET_LIST_SCHEDULE_SUCCESSFULLY: 'ACTION_GET_LIST_SCHEDULE_SUCCESSFULLY',
  ACTION_GET_LIST_SCHEDULE_FAIL: 'ACTION_GET_LIST_SCHEDULE_FAIL',
  //save shift
  ACTION_SAVE_SHIFT_SUCCESSFULLY: 'ACTION_SAVE_SHIFT_SUCCESSFULLY',
  ACTION_SAVE_SHIFT_FAIL: 'ACTION_SAVE_SHIFT_FAIL',
  ACTION_SAVE_SHIFT_FAIL_CANNOT_SAVE: 'ACTION_SAVE_SHIFT_FAIL_CANNOT_SAVE',

  //get list services
  ACTION_GET_LIST_SERVICES_SUCCESSFULLY: 'ACTION_GET_LIST_SERVICES_SUCCESSFULLY',
  ACTION_GET_LIST_SERVICES_FAIL: 'ACTION_GET_LIST_SERVICES_FAIL',
  ACTION_GET_GUESS_LIST_SERVICES_SUCCESSFULLY: 'ACTION_GET_GUESS_LIST_SERVICES_SUCCESSFULLY',
  ACTION_GET_GUESS_LIST_SERVICES_FAIL: 'ACTION_GET_GUESS_LIST_SERVICES_FAIL',

  //get service
  ACTION_GET_SERVICE_SUCCESSFULLY: 'ACTION_GET_SERVICE_SUCCESSFULLY',
  ACTION_GET_SERVICE_FAIL: 'ACTION_GET_SERVICE_FAIL',

  //delete service
  ACTION_DELETE_SERVICE_SUCCESSFULLY: 'ACTION_DELETE_SERVICE_SUCCESSFULLY',
  ACTION_DELETE_SERVICE_FAIL: 'ACTION_DELETE_SERVICE_FAIL',

  //create service
  ACTION_CREATE_SERVICE_SUCCESSFULLY: 'ACTION_CREATE_SERVICE_SUCCESSFULLY',
  ACTION_CREATE_SERVICE_FAIL: 'ACTION_CREATE_SERVICE_FAIL',
  ACTION_SERVICE_PRIORITY_VALID: 'ACTION_SERVICE_PRIORITY_VALID',
  ACTION_SERVICE_PRIORITY_INVALID: 'ACTION_SERVICE_PRIORITY_INVALID',

  // set guess choice
  ACTION_SET_GUESS_CHOICE: 'ACTION_SET_GUESS_CHOICE',

  // get guess choice
  ACTION_GET_GUEST_CHOICE: 'ACTION_GET_GUEST_CHOICE',
  ACTION_GUEST_LINE_USER_ID_VALID: 'ACTION_GUEST_LINE_USER_ID_VALID',
  ACTION_GUEST_LINE_USER_ID_INVALID: 'ACTION_GUEST_LINE_USER_ID_INVALID',
  ACTION_CLEAN_GUEST_CHOICE: "ACTION_CLEAN_GUEST_CHOICE",

  // get guess info
  ACTION_GET_GUESS_INFO_SUCCESSFULLY: 'ACTION_GET_GUESS_INFO_SUCCESSFULLY',
  ACTION_GET_GUESS_INFO_FAIL: 'ACTION_GET_GUESS_INFO_FAIL',

  //create booking
  ACTION_CREATE_BOOKING_SUCCESSFULLY: 'ACTION_CREATE_BOOKING_SUCCESSFULLY',
  ACTION_CREATE_BOOKING_FAIL: 'ACTION_CREATE_BOOKING_FAIL',

  //get booking list
  ACTION_GET_BOOKING_LIST_SUCCESSFULLY: 'ACTION_GET_BOOKING_LIST_SUCCESSFULLY',
  ACTION_GET_BOOKING_LIST_FAIL: 'ACTION_GET_BOOKING_LIST_FAIL',
  ACTION_GET_BOOKING_COUNT_FOR_MONTH_SUCCESSFULLY: "ACTION_GET_BOOKING_COUNT_FOR_MONTH_SUCCESSFULLY",
  ACTION_GET_BOOKING_COUNT_FOR_MONTH_FAIL: "ACTION_GET_BOOKING_COUNT_FOR_MONTH_FAIL",


  //get guess booking
  ACTION_GET_GUESS_BOOKING_SUCCESSFULLY: 'ACTION_GET_GUESS_BOOKING_SUCCESSFULLY',
  ACTION_GET_GUESS_BOOKING_FAIL: 'ACTION_GET_GUESS_BOOKING_FAIL',

  //cancel booking
  ACTION_CANCEL_BOOKING_SUCCESSFULLY: 'ACTION_CANCEL_BOOKING_SUCCESSFULLY',
  ACTION_CANCEL_BOOKING_FAIL: 'ACTION_CANCEL_BOOKING_FAIL',

  ACTION_GET_BOOKING_FRAMES_SUCCESSFULLY: 'ACTION_GET_BOOKING_FRAMES_SUCCESSFULLY',
  ACTION_GET_BOOKING_FRAMES_FAIL: 'ACTION_GET_BOOKING_FRAMES_FAIL',

  //check booking available
  ACTION_CHECK_BOOKING_AVAILABLE_SUCCESSFULLY: 'ACTION_CHECK_BOOKING_AVAILABLE_SUCCESSFULLY',
  ACTION_CHECK_BOOKING_AVAILABLE_FAIL: 'ACTION_CHECK_BOOKING_AVAILABLE_FAIL',
};
