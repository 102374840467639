import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import _ from 'lodash';

import Master from './layouts/master';

import Home from './pages/Home';
import MenuStore from './components/Menu/MenuStore';
import MenuSetting from './components/Menu/MenuSetting';

import LoginForm from './components/login/LoginForm';

import ChangePassword from './components/login/ChangePassword';
import StoreInforSetting from './components/login/StoreInforSetting';

import ShiftMain from './pages/shiftManagement/ShiftMain';
import BookingMain from './pages/reservationManagement/ReservationMain';

import MenuAdmin from './components/Menu/MenuAdmin';

import SalonManagement from './components/SalonManagement';

import UserManagement from './components/UserManagement';

import Listmenu from './components/menu_management/Listmenu';

import Viewseat from './components/cancelseat/viewseat';
import Crreason from './components/cancelseat/Crreason';
import Crconfirm from './components/cancelseat/Crconfirm';
import ForgetPassword from './components/login/ForgetPassword';

import Booking from './pages/booking/booking';

import UserOption from './pages/userOption/UserOption';
import UserInformation from './pages/userInformation/UserInformation';

import UserSchedule from './pages/userSchedule/UserSchedule';
import UserConfirm from './pages/userConfirm/UserConfirm';
import ResetPassword from './components/login/ResetPassword';
import UserCompletion from './pages/userCompletion/UserCompletion';
import Crcompletion from './components/cancelseat/Crcompletion';
import LinkLineAccount from './components/LinkLineAccount/index';
import LinkLineAccountSuccess from './components/LinkLineAccount/success';

import Crfailure from './components/cancelseat/Crfailure';
import BookingInvalidLineUserId from './pages/booking/booking-invalid-line-user-id';
class MainIndex extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Master>
        <Helmet>
          <title>Salon Reservation</title>
        </Helmet>

        <main id={'site-main'} role={'main'}>
          <Switch>
            {/* <Route path="/" exact render={(props) => <Home {...props} />} /> */}

            <Route path="/admin/menu" exact render={(props) => <MenuAdmin {...props} />} />
            <Route path="/admin/salon-management" exact render={(props) => <SalonManagement {...props} />} />
            <Route path="/admin/account-management" exact render={(props) => <UserManagement {...props} />} />

            <Route path="/store/menu" exact render={(props) => <MenuStore {...props} />} />
            <Route path="/setting" exact render={(props) => <MenuSetting {...props} />} />

            <Route path="/store/shiftmanagement" exact render={(props) => <ShiftMain {...props} />} />

            <Route path="/store/reservationmanagement" exact render={(props) => <BookingMain {...props} />} />

            <Route path="/store/optionmanagement" exact render={(props) => <Listmenu {...props} />} />

            <Route path="/login" exact render={(props) => <LoginForm {...props} />} />
            <Route path="/forget-password" exact render={(props) => <ForgetPassword {...props} />} />
            <Route path="/reset-password" exact render={(props) => <ResetPassword {...props} />} />

            <Route path="/change-password" exact render={(props) => <ChangePassword />} />
            <Route path="/storeinfor-setting" exact render={(props) => <StoreInforSetting />} />

            <Route path="/user/crdetails" exact render={(props) => <Viewseat {...props} />} />

            <Route path="/user/crreason" exact render={(props) => <Crreason {...props} />} />
            <Route path="/user/crconfirm" exact render={(props) => <Crconfirm {...props} />} />
            <Route path="/user/crcompletion" exact render={(props) => <Crcompletion {...props} />} />
            <Route path="/user/crfailure" exact render={(props) => <Crfailure {...props} />} />

            <Route path="/booking" exact render={(props) => <Booking {...props} />} />
            <Route path="/booking/cancel" exact render={(props) => <Booking {...props} />} />
            <Route path="/booking/booking-invalid-line-user-id" exact render={(props) => <BookingInvalidLineUserId {...props} />} />

            <Route path="/user/option" exact render={(props) => <UserOption {...props} />} />

            <Route path="/user/inputcustomerInfo" exact render={(props) => <UserInformation {...props} />} />

            <Route path="/user/schedule" exact render={(props) => <UserSchedule {...props} />} />

            <Route path="/user/confirm" exact render={(props) => <UserConfirm {...props} />} />

            <Route path="/user/completion" exact render={(props) => <UserCompletion {...props} />} />
            <Route path="/link-line-account" exact render={(props) => <LinkLineAccount {...props} />} />

            <Route path="/link-line-success" exact render={(props) => <LinkLineAccountSuccess {...props} />} />
          </Switch>
        </main>
      </Master>
    );
  }
}

export default MainIndex;
