import React, { Component } from 'react';
import InputForm from '../atoms/inputForm';

import { connect } from 'react-redux';
import { LANGUAGES, getTranslate, notify } from '../../../utils';
import _ from 'lodash';
import { TYPES } from '../../../actions/types';
import { linkLINEAccount } from '../../../actions';

class LinkLINEAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        username: '',
        password: '',
      },
      msg: {
        username: '',
        password: '',
      },
      actionNotify: {
        time: +new Date(),
      },
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    console.log('UNSAFE_componentWillReceiveProps', props);
    if (!_.isEmpty(props.actionNotify) && props.actionNotify.time > this.state.actionNotify.time) {
      console.log('props.actionNotify.type: ', props.actionNotify.type);
      switch (props.actionNotify.type) {
        case TYPES.ACTION_SALON_LINK_LINE_ACCOUNT_SUCCESS:
          // notify(getTranslate(LANGUAGES.TEXT_LINK_SALON_LINE_ACCOUNT_SUCCESSFULLY), 'success');
          this.setState({
            form: {
              username: '',
              password: '',
            },
          });
          document.getElementById('txtPassword').setCustomValidity('');
          document.getElementById('txtUsername').setCustomValidity('');

          if (this.form) this.form.classList.remove('was-validated');

          // setTimeout(() => {
          //   window.location.href = '/link-line-success';
          // }, 3000);

          window.location.href = '/link-line-success';

          break;
        case TYPES.ACTION_LOGIN_FAIL:
        case TYPES.ACTION_CALL_API_FAIL:
          let msgError = '';
          switch (props.actionNotify.error_code) {
            case 1000: // not found user
              msgError = 'ユーザーが見つかりません';
              this.setState({
                msg: {
                  ...this.state.msg,
                  username: msgError,
                  password: '',
                },
              });
              notify(msgError, 'error');
              document.getElementById('txtUsername').setCustomValidity(msgError);
              break;
            case 1002:
              msgError = 'パスワードが間違っています';
              this.setState({
                msg: {
                  ...this.state.msg,
                  password: msgError,
                },
              });
              notify(msgError, 'error');
              document.getElementById('txtPassword').setCustomValidity(msgError);

              break;
            case 1001:
              if (_.get(props, 'actionNotify.message', '').indexOf('token') > -1) {
                msgError = getTranslate(LANGUAGES.TOKEN_IS_NOT_VALID_OR_EXPIRED);
                this.setState({
                  msg: {
                    ...this.state.msg,
                    password: msgError,
                  },
                });
                notify(msgError, 'error');
                document.getElementById('txtPassword').setCustomValidity(msgError);
              }

              // setTimeout(() => {
              //   window.location.href = '/login';
              // }, 2000);

              break;
          }
          break;

        default:
          break;
      }

      this.setState({ actionNotify: props.actionNotify });
    }
  }

  onHandleChangeInput = (e, field) => {
    this.setState({
      form: {
        ...this.state.form,
        [field]: e.target.value.trim(),
      },
      msg: {
        username: '',
        password: '',
      },
    });
    document.getElementById('txtPassword').setCustomValidity('');
    document.getElementById('txtUsername').setCustomValidity('');
  };

  onHandleSubmitLogin = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let urlPrams = new URLSearchParams(window.location.search);
      this.props.linkLINEAccount({
        token: urlPrams.get('token'),
        line_id: urlPrams.get('line_id'),
        username: this.state.form.username,
        password: this.state.form.password,
      });
    }

    form.classList.add('was-validated');
  };

  render() {
    return (
      <div className="modal fade show" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" style={{ display: 'block', paddinRight: '15px', height: 'calc(100% - 200px)', top: '50%', transform: 'translateY(-50%)' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-title text-center mb-50">
                <h1 style={{ color: 'black', fontSize: '26px' }}>店舗連携申請 </h1>
              </div>

              <div className="d-flex flex-column text-center">
                <form noValidate autoComplete="off" className={'needs-validation'} onSubmit={this.onHandleSubmitLogin}>
                  <label style={{ float: 'left', color: 'black', fontSize: '16px' }}>アカウントID</label>
                  <InputForm
                    id={'txtUsername'}
                    modifyClass={' mb-10'}
                    type={'text'}
                    required={true}
                    placeholder={``}
                    onChange={(e) => this.onHandleChangeInput(e, 'username')}
                    value={this.state.form.username}
                    msg={this.state.form.username === '' ? `ユーザーIDを入力してください` : this.state.msg.username}
                  />
                  <label style={{ float: 'left', color: 'black', fontSize: '16px' }}>パスワード</label>
                  <InputForm
                    modifyClass={' mb-20'}
                    id={'txtPassword'}
                    label={'Password'}
                    defaultValue={this.state.form.password}
                    type={`password`}
                    required={true}
                    onChange={(e) => this.onHandleChangeInput(e, 'password')}
                    placeholder={``}
                    minLength="1"
                    maxLength="100"
                    msg={this.state.form.password === '' ? `パスワードを入力してください` : this.state.msg.password}
                  />

                  <button style={{ height: '42px', fontSize: '22px', marginBottom: '20px', fontWeight: 'bold' }} type="submit" className=" btn-success btn-block btn-round">
                    申請
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userInfo, token } = state.userReducer;
  const { actionNotify } = state.staffReducer;

  return {
    userInfo,
    token,
    actionNotify,
  };
};

export default connect(mapStateToProps, { linkLINEAccount })(LinkLINEAccount);
