import { TYPES } from './types';
import { staffService } from '../services';
import { param } from 'jquery';
import Func from '../utils/Func';
import { LANGUAGES, getTranslate, getTranslateByCode, notify } from '../utils';
import _ from 'lodash';

export const getShift = (date) => async (dispatch) => {
  try {
    const result = await staffService.getShift(date);
    const { data } = result;
    console.log('data: ', data);
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_GET_SHIFT_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_SHIFT_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_GET_SHIFT_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const saveShift = (params) => async (dispatch) => {
  try {
    const result = await staffService.saveShift(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_SAVE_SHIFT_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_SAVE_SHIFT_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_SAVE_SHIFT_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const getListServices = (params) => async (dispatch) => {
  try {
    const result = await staffService.getListServices(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_GET_LIST_SERVICES_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_LIST_SERVICES_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_GET_LIST_SERVICES_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const getService = () => async (dispatch) => {
  try {
    const result = await staffService.getService();
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_GET_SERVICE_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_SERVICE_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_GET_SERVICE_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const deleteService = (params) => async (dispatch) => {
  try {
    const result = await staffService.deleteService(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_DELETE_SERVICE_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_DELETE_SERVICE_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_DELETE_SERVICE_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const createService = (params) => async (dispatch) => {
  try {
    const result = await staffService.createService(params);
    const { data } = result;
    console.log('createService data: ', data);
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_CREATE_SERVICE_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CREATE_SERVICE_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CREATE_SERVICE_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const serviceCheckPriority = (params) => async (dispatch) => {
  try {
    const result = await staffService.serviceCheckPriority(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_SERVICE_PRIORITY_VALID,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_SERVICE_PRIORITY_INVALID,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_SERVICE_PRIORITY_INVALID,
      payload: {
        message: error,
      },
    });
  }
};

export const getGuessServiceList = (params) => async (dispatch) => {
  try {
    const result = await staffService.getGuessServiceList(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_GET_GUESS_LIST_SERVICES_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_GUESS_LIST_SERVICES_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_GET_GUESS_LIST_SERVICES_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const getListGuessSchedule = (params) => async (dispatch) => {
  try {
    const result = await staffService.getListGuessSchedule(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_GET_LIST_SCHEDULE_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_LIST_SCHEDULE_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_GET_LIST_SCHEDULE_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const setGuessChoice = (obj) => async (dispatch) => {
  console.log('setGuessChoice obj: ', obj);
  let data = window.localStorage.getItem('guessChoicing');
  const isValidJSON = Func.isValidJSON(data);
  data = isValidJSON ? JSON.parse(data) : {};
  window.localStorage.setItem('guessChoicing', JSON.stringify({ ...data, ...obj }));
  dispatch({
    type: TYPES.ACTION_SET_GUESS_CHOICE,
    payload: {
      data: { ...obj },
    },
  });
};

export const getGuessChoice =
  (cb = () => {}) =>
  async (dispatch) => {
    const data = window.localStorage.getItem('guessChoicing');
    const isValidJSON = Func.isValidJSON(data);
    dispatch({
      type: TYPES.ACTION_GET_GUEST_CHOICE,
      payload: {
        data: isValidJSON ? JSON.parse(data) : {},
      },
    });
    cb();
  };

export const cleanGuessChoice =
  (cb = () => {}) =>
  async (dispatch) => {
    console.log('cleanGuessChoice');
    window.localStorage.removeItem('guessChoicing');
    dispatch({
      type: TYPES.ACTION_CLEAN_GUEST_CHOICE,
      payload: {
        data: {},
      },
    });
    cb();
  };

export const getGuessInfo = (params) => async (dispatch) => {
  try {
    window.localStorage.setItem('isNewGuess', true);
    const result = await staffService.getGuessInfo(params);
    const { data } = result;
    if (data.status != 'error') {
      window.localStorage.setItem('isNewGuess', false);
      dispatch({
        type: TYPES.ACTION_GET_GUESS_INFO_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_GUESS_INFO_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_GET_GUESS_INFO_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const createBooking = (params) => async (dispatch) => {
  try {
    const isStaffLogin = Func.isStaffLogin();
    const result = await staffService.createBooking(params, isStaffLogin);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_CREATE_BOOKING_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CREATE_BOOKING_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CREATE_BOOKING_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const checkBookingAvailable = (params) => async (dispatch) => {
  try {
    const isStaffLogin = Func.isStaffLogin();
    const result = await staffService.checkBookingAvailable(params, isStaffLogin);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_CHECK_BOOKING_AVAILABLE_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CHECK_BOOKING_AVAILABLE_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CHECK_BOOKING_AVAILABLE_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const staffList = (params) => async (dispatch) => {
  try {
    const result = await staffService.staffList(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_STAFF_GET_LIST_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CALL_API_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const staffCreate = (params) => async (dispatch) => {
  try {
    const result = await staffService.staffCreate(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_STAFF_UPDATE_INFO_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CALL_API_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        data: error,
        message: error,
      },
    });
  }
};

export const staffChangePassword = (params) => async (dispatch) => {
  try {
    const result = await staffService.staffChangePassword(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_STAFF_CHANGE_PASSWORD_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CALL_API_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        data: error,
        message: error,
      },
    });
  }
};

export const staffForgetPassword = (params) => async (dispatch) => {
  try {
    const result = await staffService.staffForgetPassword(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_STAFF_FORGET_PASSWORD_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CALL_API_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        data: error,
        message: error,
      },
    });
  }
};

export const staffResetPassword = (params) => async (dispatch) => {
  try {
    const result = await staffService.staffResetPassword(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_STAFF_RESET_PASSWORD_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CALL_API_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        data: error,
        message: error,
      },
    });
  }
};

export const getBookingList = (params) => async (dispatch) => {
  try {
    const result = await staffService.getBookingList(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_GET_BOOKING_LIST_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_BOOKING_LIST_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_GET_BOOKING_LIST_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const getBookingCountForMonth = (params) => async (dispatch) => {
  try {
    const result = await staffService.getBookingCountForMonth(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_GET_BOOKING_COUNT_FOR_MONTH_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_BOOKING_COUNT_FOR_MONTH_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_GET_BOOKING_COUNT_FOR_MONTH_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const cancelBooking = (params) => async (dispatch) => {
  try {
    const result = await staffService.cancelBooking(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_CANCEL_BOOKING_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CANCEL_BOOKING_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CANCEL_BOOKING_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const cancelBookingForSalon = (params) => async (dispatch) => {
  try {
    const isStaffLogin = Func.isStaffLogin();
    const result = await staffService.cancelBookingForSalon(params, true);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_CANCEL_BOOKING_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CANCEL_BOOKING_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CANCEL_BOOKING_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const getGuessBooking = (params) => async (dispatch) => {
  try {
    const result = await staffService.getGuessBooking(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_GET_GUESS_BOOKING_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_GUESS_BOOKING_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_GET_GUESS_BOOKING_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const guestCheckValidLineUserId = (params) => async (dispatch) => {
  try {
    const result = await staffService.guestCheckValidLineUserId(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_GUEST_LINE_USER_ID_VALID,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GUEST_LINE_USER_ID_INVALID,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_GUEST_LINE_USER_ID_INVALID,
      payload: {
        message: error,
      },
    });
  }
};

export const linkLINEAccount = (params) => async (dispatch) => {
  try {
    const result = await staffService.linkLINEAccount(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_SALON_LINK_LINE_ACCOUNT_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CALL_API_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        data: error,
        message: error,
      },
    });
  }
};

export const notifyAction = (params) => async (dispatch) => {
  let { type, message, error_code } = params;
  if(!type) return;
  
  if (message === 'Network Error') {
    const notifyState = 'error';
    notify('Network error', notifyState);
    return;
  }
  if (message == 'Request failed with status code 403') {
    const notifyState = 'error';
    notify('Server error', notifyState);
    return;
  }
  if (error_code) {
    const notifyState = 'error';
    notify(getTranslateByCode(error_code), notifyState);
    return;
  }
  const exceptionalTypes = [
    'ACTION_GET_LIST_SERVICES_SUCCESSFULLY',
    'ACTION_GET_SHIFT_SUCCESSFULLY',
    'ACTION_GET_BOOKING_LIST_SUCCESSFULLY',
    'ACTION_GET_GUESS_LIST_SERVICES_SUCCESSFULLY',
    'ACTION_SET_GUESS_CHOICE',
    'ACTION_GET_GUEST_CHOICE',
    'ACTION_GET_LIST_SCHEDULE_SUCCESSFULLY',
    'ACTION_CREATE_GUESS_USER_SUCCESSFULLY',
    'ACTION_GET_GUESS_BOOKING_SUCCESSFULLY',
    'ACTION_GET_GUESS_INFO_SUCCESSFULLY',
    'ACTION_CHECK_BOOKING_AVAILABLE_SUCCESSFULLY',
  ];
  if (!exceptionalTypes.includes(type)) {
    const notifyState = 'success';
    const key = type.replace('ACTION_', 'TEXT_');
    const text = _.get(LANGUAGES, key) ? getTranslate(LANGUAGES[key]) : '';

    if (!text) {
      console.log(`missing key ${key} in language`);
    } else {
      notify(text, notifyState);
    }

    return;
  }

  // switch (type) {
  //   case TYPES.ACTION_SAVE_SHIFT_SUCCESSFULLY:

  //   case TYPES.ACTION_SAVE_SHIFT_FAIL:
  //   case TYPES.ACTION_CREATE_SERVICE_SUCCESSFULLY:
  //   case TYPES.ACTION_CREATE_SERVICE_FAIL:

  //   case TYPES.ACTION_GET_LIST_SERVICES_SUCCESSFULLY:
  //   case TYPES.ACTION_DELETE_SERVICE_FAIL:
  //   case TYPES.ACTION_GET_LIST_SCHEDULE_FAIL:
  //   case TYPES.ACTION_GET_GUESS_LIST_SERVICES_FAIL:
  //   case TYPES.ACTION_GET_GUESS_INFO_FAIL:
  //   case TYPES.ACTION_CREATE_BOOKING_FAIL:
  //   case TYPES.ACTION_CREATE_GUESS_USER_FAIL:
  //   case TYPES.ACTION_CALL_API_FAIL:
  //   case TYPES.ACTION_GET_BOOKING_LIST_FAIL:
  //   case TYPES.ACTION_CANCEL_BOOKING_FAIL:
  //   case TYPES.ACTION_GET_GUESS_BOOKING_FAIL:
  // }
};

export const staffAction = {
  getShift,
  saveShift,
  getListServices,
  getService,
  deleteService,
  createService,
  serviceCheckPriority,
  getGuessServiceList,
  getListGuessSchedule,
  setGuessChoice,
  cleanGuessChoice,
  getGuessInfo,
  createBooking,
  checkBookingAvailable,
  staffList,
  staffCreate,
  staffChangePassword,
  staffForgetPassword,
  staffResetPassword,
  getBookingList,
  getBookingCountForMonth,
  getGuessBooking,
  guestCheckValidLineUserId,
  cancelBooking,
  cancelBookingForSalon,
  linkLINEAccount,
  notifyAction,
};
