import React, { Component } from 'react';
import './../../../styles/components/viewseat.scss';
import './../../../styles/components/confirmcancel.scss';
import { connect } from 'react-redux';
import { TYPES } from '../../../actions';
import { cancelBooking, getGuessBooking, notifyAction } from '../../../actions';
import moment from 'moment';
import _ from 'lodash';
import Func from '../../../utils/Func';
class Crconfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booking_uuid: new URLSearchParams(window.location.search).get('booking_uuid'),
      data: {
        salonName: '',
        visitDateTime: '',
        totalDuration: '',
        options: '',
        totalPrice: '',
        customerName: '',
        phoneNumber: '',
        birthYear: '',
        sex: '',
        purpose: '',
      },
    };
  }
  componentDidMount() {
    this.props.getGuessBooking({
      booking_uuid: this.state.booking_uuid,
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    console.log('Crconfirm props: ', props);
    let { type } = props.actionNotify;
    const { errorCode, message } = Func.getError({ ...props });

    let showNotify = true;
    switch (type) {
      case TYPES.ACTION_GET_GUESS_BOOKING_SUCCESSFULLY:
        const data = _.get(props.actionNotify, 'booking', {});
        this.setState({ data });
        break;
      case TYPES.ACTION_GET_GUESS_BOOKING_FAIL:
        break;
      case TYPES.ACTION_CANCEL_BOOKING_SUCCESSFULLY:
        this.props.history.push('/user/crcompletion');
        break;
      case TYPES.ACTION_CANCEL_BOOKING_FAIL:
        showNotify = false;
        this.props.history.push('/user/crfailure');
        break;
      default:
        break;
    }

    if (showNotify) this.props.notifyAction({ type, error_code: errorCode, message });
  }

  onHandleCancelBooking = () => {
    this.props.cancelBooking({
      booking_uuid: this.state.booking_uuid,
    });
  };

  formatPrice = (price) => {
    if (typeof price === 'number') {
      price = price.toString();
    }

    if (typeof price !== 'string' || price.trim() === '') {
      return '0';
    }

    let [integerPart = '', decimalPart = ''] = price.split('.');

    let formattedIntegerPart = parseInt(integerPart).toLocaleString();

    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : `${formattedIntegerPart}`;
  }
  render() {
    const { data } = this.state;
    return (
      <div>
        <div className=" list-it">
          <div className=" contain-main d-flex flex-column  align-items-center">
            <h1 style={{ color: 'black', fontSize: '26px' }}>ご予約のキャンセル確認</h1>
            <a style={{ color: 'red', fontSize: '16px' }}>以下の予約をキャンセルしてもよろしいですか？</a>
            <div className="content-main2 d-flex justify-content-center">
              <div style={{ maxWidth: 500, width: '100%' }}>
                <div className="gray-bg">
                  <h1 style={{ color: 'black', fontSize: '16px' }}>ご予約内容</h1>
                </div>
                <h1 style={{ color: 'black', fontSize: '16px' }}>サロン名</h1>
                <p style={{ color: 'black', fontSize: '16px', fontWeight: 400 }}>{data.salonName}</p>
                <h1 style={{ color: 'black', fontSize: '16px' }}>来店日時</h1>
                <p style={{ color: 'black', fontSize: '16px', fontWeight: 400 }}> {data.visitDateTime}</p>
                <h1 style={{ color: 'black', fontSize: '16px' }}>所要時間合計（目安） </h1>
                <p style={{ color: 'black', fontSize: '16px', fontWeight: 400 }}>{data.totalDuration}</p>
                <h1 style={{ color: 'black', fontSize: '16px' }}>コース </h1>
                <p style={{ color: 'black', fontSize: '16px', fontWeight: 400 }}>{data.options}</p>
                <h1 style={{ color: 'black', fontSize: '16px' }}>合計金額 </h1>
                <p style={{ color: 'black', fontSize: '16px', fontWeight: 400 }}>{this.formatPrice(data.totalPrice)}円</p>

                <div className="gray-bg">
                  <h1 style={{ color: 'black', fontSize: '16px' }}>お客様情報</h1>
                </div>
                <h1 style={{ color: 'black', fontSize: '16px' }}>ご予約者氏名 </h1>
                <p style={{ color: 'black', fontSize: '16px', fontWeight: 400 }}>{data.customerName}</p>
                <h1 style={{ color: 'black', fontSize: '16px' }}>電話番号 </h1>
                <p style={{ color: 'black', fontSize: '16px', fontWeight: 400 }}>{data.phoneNumber}</p>
                <h1 style={{ color: 'black', fontSize: '16px' }}>生年 </h1>
                <p style={{ color: 'black', fontSize: '16px', fontWeight: 400 }}>{data.birthYear}</p>
                <h1 style={{ color: 'black', fontSize: '16px' }}>性別</h1>
                <p style={{ color: 'black', fontSize: '16px', fontWeight: 400 }}>{data.sex}</p>
                <h1 style={{ color: 'black', fontSize: '16px' }}>ご来店の目的・ご要望・ご質問</h1>
                <p style={{ color: 'black', fontSize: '16px', fontWeight: 400 }}>{data.purpose}</p>
              </div>
            </div>
            <p style={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}>※一度キャンセルするとご予約は元に戻せません。</p>
            <button style={{ fontSize: '22px', height: 48, minWidth: 200 }} className="btn-cf btn-success" onClick={this.onHandleCancelBooking}>
              予約キャンセル
            </button>
            <div className="d-flex justify-content-start w-90">{/* <a href="/user/crdetails"><button  className="btn-cancel">戻る</button></a> */}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { staffReducer } = state;

  return {
    actionNotify: staffReducer.actionNotify,
  };
}

export default connect(mapStateToProps, {
  getGuessBooking,
  cancelBooking,
  notifyAction,
})(Crconfirm);
