import React, { Component } from 'react';
import InputForm from '../atoms/inputForm';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import _ from 'lodash';
import { TYPES, staffForgetPassword } from '../../../actions';

import { LANGUAGES, getTranslate, checkValidate, notify } from '../../../utils';

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,

      userInfo: {},

      formMode: 'update',
      form: {
        username: '',
      },
      formIsValid: false,
      msg: {},
      msg_notify: '',

      actionNotify: {
        time: +new Date(),
      },
    };

    this.validates = [{ field: 'username', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_ACCOUNT_ID) }];
  }

  UNSAFE_componentWillReceiveProps(props) {
    let newState = {
      ..._.pick(props, ['userInfo']),
    };

    ['userInfo'].forEach((field) => {
      if (JSON.stringify(newState[field]) == this.state[field]) {
        delete newState[field];
      }
    });

    if (!_.isEmpty(newState)) {
      this.setState(newState);
    }

    if (!_.isEmpty(props.actionNotify) && props.actionNotify.time > this.state.actionNotify.time) {
      switch (props.actionNotify.type) {
        case TYPES.ACTION_STAFF_FORGET_PASSWORD_SUCCESS:
          let msgNotify = getTranslate(LANGUAGES.TEXT_MESSAGE_FORGET_PASSWORD);
          this.setState({
            form: {
              username: '',
            },
            msg_notify: msgNotify,
          });
          document.getElementById('txtUsername').setCustomValidity('');

          if (this.form) this.form.classList.remove('was-validated');

          break;

        case TYPES.ACTION_CALL_API_FAIL:
          let msgError = '';
          switch (props.actionNotify.error_code) {
            case 1000: // not found user
              msgError = 'ユーザーが見つかりません';
              this.setState({
                msg: {
                  ...this.state.msg,
                  username: msgError,
                  password: '',
                },
              });
              notify(msgError, 'error');
              document.getElementById('txtUsername').setCustomValidity(msgError);
              break;
            case 1002:
              msgError = 'パスワードが間違っています';
              this.setState({
                msg: {
                  ...this.state.msg,
                  password: msgError,
                },
              });
              notify(msgError, 'error');
              document.getElementById('txtPassword').setCustomValidity(msgError);

              break;
            case 1201:
              msgError = getTranslate(LANGUAGES.TEXT_CAN_NOT_RESET_ADMIN_PASSWORD);
              this.setState({
                msg: {
                  ...this.state.msg,
                  username: msgError,
                },
              });
              notify(msgError, 'error');
              document.getElementById('txtUsername').setCustomValidity(msgError);

              break;
            default:
              break;
          }

        default:
          break;
      }
      this.setState({
        actionNotify: props.actionNotify,
      });
    }
  }

  onChangeFormField = (e, field) => {
    const value = e.target.value;
    let newForm = {
      ...this.state.form,
      [field]: value,
    };

    this.setState({
      form: newForm,
      msg: {},
      msg_notify: '',
    });

    document.getElementById('txtUsername').setCustomValidity('');

    if (this.form) this.form.classList.remove('was-validated');
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    this.form = form;

    let validates = this.validates.filter((x) => !x.mode || x.mode.indexOf(this.state.formMode) > -1);

    let msg = checkValidate(validates, this.state.form, true);

    let formIsValid = _.isEmpty(msg);

    this.setState({
      msg,
      formIsValid,
    });

    document.getElementById('txtUsername').setCustomValidity(_.get(msg, 'username', ''));

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      console.log('form is invalid');
    } else {
      console.log('form is valid');
      this.props.staffForgetPassword({
        username: _.get(this.state, 'form.username'),
      });
    }

    form.classList.add('was-validated');
  };

  render() {
    return (
      <div className="text-center" style={{ margin: '0.5rem' }}>
        <h1 style={{ color: 'black', fontSize: '26px', fontWeight: 'bold' }}>パスワードを忘れた場合</h1>

        <p style={{ color: 'black', fontSize: '14px', marginBottom: 0 }}>登録されているアカウントIDを入力していただき、</p>
        <p style={{ color: 'black', fontSize: '14px', marginBottom: '20px' }}>「次に進む」ボタンをクリックしてください。</p>
        <p style={{ color: 'blue', fontSize: '14px', marginBottom: 0, marginTop: '20px', whiteSpace: 'pre-wrap', lineHeight: '1' }}>{this.state.msg_notify}</p>
        <div className="d-flex justify-content-center">
          <form noValidate autoComplete="off" className={'needs-validation w-100'} style={{ maxWidth: '500px' }} onSubmit={this.handleSubmit}>
            <div className="w-100 d-flex flex-column">
              <label className="d-flex" style={{ float: 'left', color: 'black', fontSize: '16px' }}>
                アカウントID
              </label>
              <InputForm
                className="mx-auto"
                style={{ width: '100%', padding: '8px 10px', maxWidth: '500px' }}
                type={'text'}
                id="txtUsername"
                required="true"
                value={this.state.form.username}
                msg={_.get(this.state, 'msg.username', '')}
                onChange={(e) => this.onChangeFormField(e, 'username')}
              />
            </div>

            <button style={{ width: '100%', marginTop: '10px', fontSize:'22px',fontWeight:'bold' }} type="submit" className="btn btn-success btn-block btn-round">
              次に進む
            </button>
            <p style={{ color: 'red', fontSize: '14px', visibility: this.state.showError ? 'visible' : 'hidden' }}>{this.state.errorMsg}</p>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userInfo } = state.userReducer;
  const { actionNotify } = state.staffReducer;

  return {
    userInfo,
    actionNotify,
  };
};

export default connect(mapStateToProps, { staffForgetPassword })(ForgetPassword);
