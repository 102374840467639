import React, { Component } from 'react';
import TabBar from '../../components/tabmenu/TabBar';
import OptionSelect from './OptionSelect';
import '../../../styles/components/userOption.scss';
import HeaderUser from '../../includes/HeaderUser';
export default class UserOption extends Component {
  componentDidMount() {
    window.localStorage.removeItem('guessChoicing');
  }
  render() {
    return (
      <div className="container-fluid" style={{ maxWidth: 1200 }}>
        <HeaderUser />
        <TabBar tab={1} description="コースメニューを選んでください。" />

        <OptionSelect />
      </div>
    );
  }
}
