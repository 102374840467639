import React, { Component } from 'react'
import Schedule from './Schedule'
import TabBar from '../../components/tabmenu/TabBar'
import BoardInforOption from './BoardInforOption'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import HeaderUser from '../../includes/HeaderUser';
import '../../../styles/components/userSchedule.scss';

// import "../../../styles/components/userSchedule.scss"
export default class UserSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.tab,
      description: props.description,
      line_id: (new URLSearchParams(window.location.search)).get('line_user_id') ?? "",
      salon_uuid: (new URLSearchParams(window.location.search)).get('sid') ?? "",
    };
  }

  onHandleBack = () => {
    window.location.href = `/user/option?sid=${this.state.salon_uuid}&line_user_id=${this.state.line_id}`;
  }
  render() {
    return (
      <div className='w-100 user-schedule-container'>
        <div className='container-fluid' style={{ maxWidth: 1200 }}>
          <HeaderUser />
          <TabBar tab={2} description="以下の予約可能日時の中からご来店希望日時を選択してください。" />
          <BoardInforOption />

        </div>
        <div className='container-fluid' style={{ maxWidth: 1200 }}>
          <Schedule />

        </div>

        <div className='container-fluid d-flex justify-content-start mt-30' style={{ maxWidth: 1200 }}>
          <button
            onClick={() => this.onHandleBack()}
            className="btn btn-light px-20 font-weight-bold mb-20" style={{ border: "1px solid", fontSize:"22px" }}>
            戻る
          </button>
        </div>

      </div>
    )
  }
}
