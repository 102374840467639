export * from './Func';
export * from './languages';

export const checkValidate = (validate, form, isSimpleMessage) => {
  let result = {};
  validate.forEach((v) => {
    let { field, type, value, msg } = v;

    let formValue = form[field] || '';

    if (type == 'required' && value) {
      if ((typeof formValue == 'string' && !formValue.trim()) || (typeof formValue != 'string' && formValue == undefined)) {
        result[field] = {
          ...(result[field] || {}),
          [type]: msg || `${field} is required`,
        };
      }
    } else if (type == 'max_length') {
      if ((formValue || '').length > value) {
        result[field] = {
          ...(result[field] || {}),
          [type]: msg || `${field} have more than ${value} characters`,
        };
      }
    } else if (type == 'min_length') {
      if ((formValue || '').length < value) {
        result[field] = {
          ...(result[field] || {}),
          [type]: msg || `${field} have less than ${value} characters`,
        };
      }
    }
  });

  console.log('result', result);

  if (isSimpleMessage) {
    Object.keys(result).forEach((k) => {
      result[k] = result[k][Object.keys(result[k])[0]];
    });
  }

  console.log('result', result);

  return result;
};

/**
 *
 * @param {* string} message
 * @param {* success or error} type
 */
export const notify = (message, type, time) => {
  const $ = window.$;

  $.notify(message, {
    className: type || 'success',
    autoHideDelay: time || 5000,
  });
};
