import React, { Component } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { TYPES } from '../../../actions';
import { getGuessInfo, setGuessChoice, getGuessChoice, notifyAction } from '../../../actions';
import Func from '../../../utils/Func';
import _ from 'lodash';

import { LANGUAGES, getTranslate, notify } from '../../../utils';
import moment from 'moment';

class UserInformationForm extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      user: {
        name: user?.name || '',
        phone: user?.phone || '',
        birthYear: user?.birthYear || '1980',
        gender: user?.gender !== undefined ? user.gender : null,
        purpose: '',
      },
      msg: {
        name: '',
        phone: '',
        birthYear: '',
        gender: '',
        purpose: '',
      },
      years: this.generateYears(1900, new Date().getFullYear()),
      areaCount: user?.purpose ? user.purpose.length : 0,
      line_id: new URLSearchParams(window.location.search).get('line_user_id') ?? '',
      salon_uuid: new URLSearchParams(window.location.search).get('sid') ?? '',
      salon_gender: null,
      isNewGuess: true,
    };

    this.onHandleValidation = this.onHandleValidation.bind(this);
  }

  componentDidMount() {
    this.props.getGuessChoice();
    this.props.getGuessInfo({
      line_id: this.state.line_id,
      salon_uuid: this.state.salon_uuid,
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    console.log('props: ', props);
    let type = props.actionNotify.type;

    if (JSON.stringify(this.state.actionSalonNotify) !== JSON.stringify(props.actionSalonNotify)) {
      type = props.actionSalonNotify.type;
      this.setState({
        actionSalonNotify: props.actionSalonNotify,
      });
    }

    const { errorCode, message } = Func.getError({ ...props });
    if (!type) return;
    const isNewGuess = window.localStorage.getItem('isNewGuess');

    let userLocal = _.get(JSON.parse(window.localStorage.getItem('guessChoicing') || '{}'), 'user', {}) || {};

    switch (type) {
      case TYPES.ACTION_GET_GUESS_INFO_SUCCESSFULLY:
        let user = props.actionNotify.guessInfo;

        this.setState(
          {
            user: { ...user, purpose: this.state.user.purpose, ...userLocal },
            isNewGuess: isNewGuess === 'true' ? true : false,
          },
          () => {
            this.props.setGuessChoice({ user: this.state.user });
          }
        );
        break;
      case TYPES.ACTION_GET_GUESS_INFO_FAIL:
        if (Func.isStaffLogin()) {
          return;
        }
        break;
      case TYPES.ACTION_GET_GUEST_CHOICE:
        const userSavedLocal = _.get(props, 'actionNotify.guessChoicing.user', {});

        if (!_.isEmpty(userSavedLocal)) {
          this.setState({
            user: { ...userSavedLocal },
          });
        }

        break;
      case TYPES.ACTION_GET_GUESS_SALON_SUCCESS:
        const salonGender = _.get(props, 'actionSalonNotify.salon_gender', '');

        this.setState(
          {
            user: { ...this.state.user, ...userLocal },
            salon_gender: salonGender,
          },
          () => {
            console.log('salon_gender: ', this.state.salon_gender);
          }
        );
        break;
      default:
        break;
    }

    if (window.location.pathname == '/user/inputcustomerInfo' && errorCode === 1000) {
      // skip notify error NOT FOUND USER for new customer
      return;
    }

    this.props.notifyAction({ type, error_code: errorCode, message });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      const { user } = this.props;
      this.setState(
        {
          user: {
            name: user?.name || '',
            phone: user?.phone || '',
            birthYear: user?.birthYear || '',
            gender: user?.gender !== undefined ? user.gender : '',
            purpose: user?.purpose || '',
          },
          areaCount: user?.purpose ? user.purpose.length : 0,
        },
        () => this.props.setGuessChoice({ user: this.state.user })
      );
    }
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log('name: ', name);
    console.log('value: ', value);
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
      areaCount: name === 'purpose' ? value.length : prevState.areaCount,
    }));
  };

  handleGenderChange = (event) => {
    const gender = parseInt(event.target.value);
    const { user } = this.state;
    if (user.gender === gender || isNaN(gender)) return;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        gender: gender,
      },
    }));
  };

  generateYears(startYear, endYear) {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  }

  onHandleValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.validateInput(name, value);
  };

  validateInput = (name, value, cb = (msgError) => {}) => {
    let msgError = '';
    if (name === 'phone') {
      if (value.trim() === '') {
        msgError = getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_USER_PHONE);
      } else if (value.trim().length < 10) {
        msgError = getTranslate(LANGUAGES.TEXT_PHONE_NUMBER_HAVE_LESS_THAN_10_CHARACTERS);
      } else if (value.trim().length > 12) {
        msgError = getTranslate(LANGUAGES.TEXT_PHONE_NUMBER_HAVE_MORE_THAN_12_CHARACTERS);
      }
    } else if (name === 'name') {
      if (value.trim() === '') {
        msgError = getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_USER_NAME);
      }
    } else if (name === 'birthYear') {
      if (value.trim() === '') {
        msgError = getTranslate(LANGUAGES.TEXT_PLEASE_SELECT_THE_YEAR_OF_BIRTH);
      }
    } else if (name === 'gender') {
      if (isNaN(parseInt(value))) {
        msgError = getTranslate(LANGUAGES.TEXT_PLEASE_SELECT_THE_GENDER);
      }
    } else if (name === 'purpose') {
      if (value.trim() === '') {
        msgError = getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_PURPOSE_OF_VISIT);
      }
    }

    const { msg } = this.state;
    msg[name] = msgError;
    this.setState(
      {
        msg,
      },
      () => {
        cb(msgError);
      }
    );
  };

  onHandleInput = (event) => {
    const name = event.target.name;
    if (name === 'phone') {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    } else if (name === 'purpose') {
      event.target.value = event.target.value.substring(0, 200);
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log('this.state: ', this.state);
    if (!(await this.checkValidation())) {
      return;
    }
    if (this.state.salon_gender === 'female' && this.state.user.gender !== 1) {
      notify('当サロンは女性専用サロンの為、\n女性以外はご利用いただけません。', 'error');
      return;
    }
    this.props.setGuessChoice({ user: this.state.user });
    if (this.props.history) {
      this.props.history.push(`/user/confirm?sid=${this.state.salon_uuid}&line_user_id=${this.state.line_id}`);
    } else {
      window.location.href = `/user/confirm?sid=${this.state.salon_uuid}&line_user_id=${this.state.line_id}`;
    }
  };

  handleGoBack = () => {
    window.location.href = `/user/schedule?sid=${this.state.salon_uuid}&line_user_id=${this.state.line_id}`;
  };

  checkValidation = async () => {
    let isValid = true;

    const validateField = (key, value) => {
      return new Promise((resolve) => {
        this.validateInput(key, value === 0 ? '0' : value || '', (v) => {
          if (v) {
            notify(v, 'error');
            isValid = false;
          }
          resolve();
        });
      });
    };

    const validationPromises = Object.keys(this.state.user).map((key) => {
      const value = _.get(this.state, `user.${key}`);
      return validateField(key, value);
    });

    await Promise.all(validationPromises);

    return isValid;
  };

  renderInputField(labelText, name, placeholder) {
    return (
      <div className="form-field d-flex flex-column mb-30">
        <div className="d-flex flex-row w-100 text-center">
          <label style={{ fontSize: '16px' }} className="font-weight-bold" htmlFor={name}>
            {labelText}
          </label>
          <span style={{ fontSize: '16px' }} className="span-req req">
            必須
          </span>
        </div>
        <input
          type={'text'}
          id={name}
          name={name}
          className="form-input py-1 px-1"
          placeholder={placeholder}
          value={this.state.user[name]}
          tabIndex={0}
          onChange={this.handleInputChange}
          onInput={this.onHandleInput}
          onBlur={this.onHandleValidation}
        />
        {this.state.msg[name] && <p className="text-danger font-weight-bold">{this.state.msg[name]}</p>}
      </div>
    );
  }

  render() {
    const { birthYear } = this.state.user;
    const { isNewGuess } = this.state;

    let scheduleTime = !_.get(this.props, 'guessChoicing.schedules[0]') ? null : moment(_.get(this.props, 'guessChoicing.schedules[0]'), 'YYYY-MM-DD HH:mm:ss');

    let visitDateTime = !scheduleTime ? '' : scheduleTime.format(`MM月DD日（${Func.getJapaneseDayOfWeek(scheduleTime.day())}）HH:mm`);

    return (
      <div className="w-100 text-dark d-flex justify-content-start">
        <div className="w-100 container-form_information" style={{ maxWidth: 500 }}>
          <h1 className="title-type mb-20" style={{ fontSize: '16px' }}>
            来店日時: {visitDateTime} ({Number(_.get(this.props.guessChoicing, 'option.total_time')) * 30}分)
          </h1>
          <h1 className="title-type" style={{ fontSize: '16px' }}>
            {!isNewGuess ? '2回目以降ご来店の方' : '初めてご来店の方'}
          </h1>
          <h2 style={{ backgroundColor: '#f2f2f2', fontSize: '16px' }} className="title-form px-10 my-30">
            お客様情報
          </h2>
          <div className="form-information">
            {this.renderInputField('ご予約者氏名', 'name', '')}
            {this.renderInputField('電話番号(ハイフン無し)', 'phone', '')}
            {isNewGuess && (
              <>
                <div className="form-age d-flex flex-column font-weight-bold mb-30">
                  <div className="d-flex flex-row w-100 text-center">
                    <label style={{ fontSize: '16px' }} className="font-weight-bold" htmlFor="form-age">
                      生年
                    </label>
                    <span style={{ fontSize: '16px' }} className="span-req req">
                      必須
                    </span>
                  </div>
                  <div>
                    <select
                      id="form-age"
                      className="form-select w-20 py-1 text-center font-weight-bold"
                      name="birthYear"
                      value={moment(birthYear).format('YYYY') || ''}
                      onChange={this.handleInputChange}
                      onBlur={this.onHandleValidation}
                    >
                      {!this.state.user.birthYear && <option value="">年</option>}
                      {this.state.years.map((year) => (
                        <option key={year} value={year}>
                          {year}年
                        </option>
                      ))}
                    </select>
                  </div>
                  {_.get(this.state, 'msg.birthYear') && <p className="text-danger">{_.get(this.state, 'msg.birthYear')}</p>}
                </div>
                <div className="form-gender d-flex flex-column font-weight-bold mb-30">
                  <div className="d-flex flex-row w-100 text-center">
                    <label style={{ fontSize: '16px' }} className="font-weight-bold">
                      性別
                    </label>

                    <span style={{ fontSize: '16px' }} className="span-req req">
                      必須
                    </span>
                  </div>
                  <div className="radio-buttons-gender d-flex flex-row w-80 justify-content-start">
                    {[
                      { label: '男', value: 0 },
                      { label: '女', value: 1 },
                      { label: 'その他', value: 2 },
                    ].map((option) => (
                      <label key={option.value} className="d-flex flex-row text-center align-items-center mr-20" style={{ cursor: 'pointer', fontSize: '16px' }}>
                        <input
                          className="mr-10 radio-gender"
                          style={{ cursor: 'pointer' }}
                          type="radio"
                          name="gender"
                          value={option.value}
                          checked={this.state.user.gender === option.value}
                          onChange={this.handleGenderChange}
                          onBlur={this.onHandleValidation}
                        />
                        <p style={{ userSelect: 'none', fontSize: '16px' }} className="m-0 name-gender">
                          {option.label}
                        </p>
                      </label>
                    ))}
                  </div>
                  {_.get(this.state.msg, 'gender') && <p className="text-danger">{_.get(this.state.msg, 'gender')}</p>}
                </div>
              </>
            )}
            <div className="form-field d-flex font-weight-bold flex-column mb-30">
              <div className="d-flex flex-row justify-content-between font-weight-bold">
                <div className="d-flex flex-row w-100 text-center">
                  <label style={{ fontSize: '16px' }} className="font-weight-bold" htmlFor="area-infor">
                    ご来店の目的・ご要望・ご質問
                  </label>
                  <span style={{ fontSize: '16px' }} className="span-req req">
                    必須
                  </span>
                </div>
                <span>({this.state.areaCount}/200)</span>
              </div>
              <textarea
                style={{ minHeight: 100 }}
                id="area-infor"
                name="purpose"
                className="form-textarea py-1 px-1"
                placeholder=""
                value={this.state.user.purpose || ''}
                onChange={this.handleInputChange}
              />
              {_.get(this.state.msg, 'purpose') && <p className="text-danger">{_.get(this.state.msg, 'purpose')}</p>}
            </div>
            <div className="d-flex justify-content-center mb-20">
              <button style={{ fontSize: '22px' }} className="btn btn-success px-10 font-weight-bold" onClick={this.handleSubmit}>
                予約内容を確認する
              </button>
            </div>
            <div className="d-flex justify-content-start">
              <button onClick={this.handleGoBack} className="btn btn-light px-20 mb-20 font-weight-bold" style={{ border: '1px solid', fontSize: '22px' }}>
                戻る
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { staffReducer, salonReducer } = state;

  return {
    actionNotify: staffReducer.actionNotify,
    guessChoicing: staffReducer.guessChoicing,
    actionSalonNotify: salonReducer.actionNotify,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getGuessInfo,
    setGuessChoice,
    getGuessChoice,
    notifyAction,
  })(UserInformationForm)
);
