import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Func from '../../utils/Func';
import { getTranslate, LANGUAGES } from '../../utils';

import { getUserInfo, refreshToken, saveUserInfo, cleanUserInfo, cleanGuessChoice, getGuessChoice } from '../../actions';
import _ from 'lodash';

import { isMobileOnly } from 'react-device-detect';
import { TYPES } from '../../actions/types';
import { withRouter } from 'react-router-dom';

class Header extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      token: localStorage.getItem('salon_token'),
      actionNotify: {
        time: +new Date(),
      },
      userInfo: null,
    };
  }

  componentDidMount() {
    if (
      !localStorage.getItem('salon_token') &&
      window.location.pathname.indexOf('login') === -1 &&
      [
        // '/forget-password', //
        // '/reset-password',
        // '/user/crdetails',
        // '/user/crreason',
        // '/user/crconfirm',
        // '/user/crcompletion',
        // '/booking',
        // '/user/option',
        // '/user/inputcustomerInfo',
        // '/user/schedule',
        // '/user/confirm',
        // '/user/completion',
        // '/link-line-account',
        '/admin/menu',
        '/admin/salon-management',
        '/admin/account-management',
        '/store/menu',
        '/store/shiftmanagement',
        '/store/reservationmanagement',
        '/store/optionmanagement',
        '/change-password',
        '/',
        "/storeinfor-setting"
      ].indexOf(window.location.pathname) > -1
    ) {
      window.location.href = '/login';
    }

    if (window.location.pathname == '/booking') {
      localStorage.removeItem('salon_token');
    }

    if (localStorage.getItem('salon_token')) {
      this.props.getUserInfo();
    }
  }

  componentWillUnmount() {}

  UNSAFE_componentWillReceiveProps(props) {
    if (JSON.stringify(props.userInfo) != JSON.stringify(this.state.userInfo)) {
      this.setState({ userInfo: props.userInfo }, () => {
        if (
          [
            '/admin/menu',
            '/admin/salon-management',
            '/admin/account-management',
            '/store/menu',
            '/setting',
            '/store/shiftmanagement',
            '/store/reservationmanagement',
            '/store/optionmanagement',
            '/login',
            '/forget-password',
            '/reset-password',
            '/change-password',
            '/storeinfor-setting',
            '/user/crdetails',
            '/user/crreason',
            '/user/crconfirm',
            '/user/crcompletion',
            '/user/crfailure',
            '/booking',
            '/user/option',
            '/user/inputcustomerInfo',
            '/user/schedule',
            '/user/confirm',
            '/user/completion',
            '/link-line-account',
            '/link-line-success',
            '/booking/booking-invalid-line-user-id',
          ].indexOf(window.location.pathname) == -1
        ) {
          this.onHanldeClickBack();
        }
      });
    }
    const { type } = props.actionNotify;
    if (!_.isEmpty(props.actionNotify) && props.actionNotify.time > this.state.actionNotify.time) {
      switch (type) {
        case TYPES.ACTION_USER_GET_USER_INFO_SUCCESS:
          // const is_admin = props.userInfo.is_admin;
          this.props.saveUserInfo(props.userInfo);
          break;
      }
    }
  }
  onHandleLogout = () => {
    this.props.cleanUserInfo();
    localStorage.removeItem('salon_token');
    window.location.href = '/login';
  };

  componentDidUpdate(prevProps) {
    // console.log("prevProps: ", prevProps);
    // if (this.props.token && this.props.token !== prevProps.token) {
    //   this.setState({ token: this.props.token });
    //   window.location.href = '/';
    // }
  }

  onHanldeClickBack = () => {
    let isRoleAdmin = _.get(this.props, 'userInfo.role') === 0;
    let isRoleSalon = _.get(this.props, 'userInfo.role') === 1;

    if (isRoleAdmin) {
      // window.history.pushState({}, '', '/admin/menu');
      this.props.history.push('/admin/menu');
      return;
    }
    if (isRoleSalon) {
      let parrentMenu = '/store/menu';
      // window.history.pushState({}, '', '/store/menu');
      this.props.cleanGuessChoice(() => {
        this.props.getGuessChoice(() => {
          setTimeout(() => {
            this.props.history.push(parrentMenu);
          }, 200);
        });
      });

      return;
    }
  };

  render() {
    // if (Func.isLoginRequire() && window.location.pathname == '/login')
    //   return <></>;
    console.log('check role:', _.get(this.props, 'userInfo.role'));

    let isLogin = !!_.get(this.props.userInfo, 'username');

    let role = _.get(this.props, 'userInfo.role');
    let isAdmin = role === 0;
    let isStaff = role === 1;

    const pathsWithoutLogoutAndID = [
      '/user/option',
      '/user/schedule',
      '/user/inputcustomerInfo',
      '/user/confirm',
      '/user/completion',
      '/user/crconfirm',
      '/login',
      '/link-line-account',
      '/forget-password',
      '/reset-password',
      '/user/crcompletion',
    ];
    const hideLogoutAndID = pathsWithoutLogoutAndID.includes(window.location.pathname) || !isLogin;

    let isHideBackButton =
      [
        '/store/menu',
        '/admin/menu',
        '/login',
        '/forget-password',
        '/reset-password',
        '/link-line-account',
        '/user/crconfirm',
        '/user/crcompletion',
        '/link-line-success',
        '/user/crfailure',
        '/booking/booking-invalid-line-user-id',
        '/',
      ].indexOf(window.location.pathname) > -1;
    if (['/user/schedule', '/user/inputcustomerInfo', '/user/confirm', '/user/option', '/user/completion'].includes(window.location.pathname)) {
      if (isAdmin || isStaff) {
        isHideBackButton = false;
      } else {
        isHideBackButton = true;
      }
    }

    return (
      <header id="site-header">
        <div className="container-fluid px-lg-50 py-20">
          <div className="d-flex align-items-end py-15">
            <div className="logo col-4 mx-0 px-0  mb-auto">
              <a style={{ width: 'fit-content' }} href={{ 0: '/admin/menu', 1: '/store/menu' }[role] || '/login'} className={'d-block fs-0 h-100'}>
                <img src="/static/images/brand/logo_01.png" alt="Salon Reservation" />
              </a>
            </div>

            {!hideLogoutAndID && (
              <div className="my-account ml-auto col-8 text-right text-dark ">
                <div className="row list-unstyled fs-10 font-weight-bold " style={{ justifyContent: 'flex-end', whiteSpace: 'nowrap' }}>
                  <div className={`col-12 salon-my-account__item d-inline-block p-0 ${isMobileOnly ? 'fs-14' : 'fs-16'}`}>
                    <span className="mre-0">{['アカウントID:', _.get(this.props, 'userInfo.username', '')].join(' ')}</span>
                  </div>
                  <div className={`col-12 my-account__item d-inline-block p-0 ${isMobileOnly ? 'fs-14' : 'fs-16'} ml-10`} style={{ whiteSpace: 'nowrap' }}>
                    {[
                      // _.get(this.props.userInfo, 'username', '') == 'admin'
                      //   ? ['Database Management', '/database-management', null]
                      //   : null,
                      // ['会員情報', '#', null],
                      // _.get(this.props.userInfo, 'username', '')
                      //   ? ['ログアウト', '/logout', this.onHandleLogout]
                      //   : null,
                      ['', '/login', this.onHandleLogout],
                    ]
                      .filter((item) => item)
                      .map((item, idx, arr) => (
                        <React.Fragment key={idx}>
                          <span className={arr.length - 1 != idx ? 'mr-10' : ''}>
                            <a className="ml-10" href={item[1]} onClick={item[2]}>
                              {item[0]}
                            </a>
                          </span>
                          {arr.length - 1 != idx && <span>|</span>}
                        </React.Fragment>
                      ))}
                    <span className="mr-10">
                      {/* <a className="ml-10 text-dark" href="#">
                        会員情報
                      </a> */}
                      <a></a>
                    </span>

                    {/* <span>|</span> */}

                    {/* {_.get(this.props.userInfo, 'username', '') && (
                    <a
                      className="ml-10 text-dark"
                      onClick={this.onHandleLogout}
                      href="/logout"
                    >
                      ログアウト
                    </a>
                  )} */}

                    <a className="ml-10 text-dark" onClick={this.onHandleLogout} href="/login">
                      <i class="fa fa-sign-out"></i>&ensp;ログアウト
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
          {!isHideBackButton && (
            <div className="col-12 px-0">
              <button className="btn btn-outline-success" onClick={this.onHanldeClickBack}>
                {getTranslate(LANGUAGES.TEXT_BACK)}
              </button>
            </div>
          )}
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  const { userInfo, token, actionNotify } = state.userReducer;

  return {
    userInfo,
    token,
    actionNotify,
  };
};

export default connect(mapStateToProps, {
  getUserInfo,
  refreshToken,
  saveUserInfo,
  cleanUserInfo,
  cleanGuessChoice,
  getGuessChoice,
})(withRouter(Header));
