import React, { Component } from 'react';

import $ from 'jquery';

class InputForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCountry = (e, c, key) => {
    this.setState({
      country: this.props.listCountry.length ? this.props.listCountry[key] : this.state.country,
    });
    this.props.onCountry(e, c.code);
  };

  render() {
    if (this.props.buttonId) {
      setTimeout(() => {
        $('input').css('padding-right', $(`#${this.props.buttonId}`).css('width'));
      }, 100);
    }

    return (
      <div className={`form-group${this.props.modifyClass || ''}`}>
        {this.props.required ? (
          <>
            <div className={`position-relative`}>
              <div>
                <input
                  id={this.props.id}
                  type={this.props.type}
                  ref={this.props.ref}
                  name={this.props.name}
                  className={`form-control form-control${this.props.originType === ' password' ? ' pr-35' : ''}${this.props.classInput ? ` ${this.props.classInput}` : ''}${
                    this.props.showVerify || this.props.showCountry ? ' country-input' : ''
                  }${this.props.showVerify ? ' pr-100' : ''}`}
                  required={true}
                  style={this.props.style}
                  placeholder={this.props.placeholder}
                  autoComplete={this.props.autoComplete ? this.props.autoComplete : 'off'}
                  value={this.props.value}
                  onChange={this.props.onChange}
                  onKeyPress={this.props.onKeyPress}
                  onKeyDown={this.props.onKeyDown}
                  onKeyUp={this.props.onKeyUp}
                  onBeforeInput={this.props.onBeforeInput}
                  onFocus={this.props.onFocus}
                  minLength={this.props.minLength}
                  maxLength={this.props.maxLength}
                  pattern={this.props.pattern}
                  step={this.props.step}
                  disabled={this.props.disabled}
                  onBlur={this.props.onBlur}
                  defaultValue={this.props.defaultValue}
                />
                <div className={'invalid-feedback text-left'}>{this.props.msg || ''}</div>
                {this.props.msgError && <div className={'text-left fs-12 text-ub-red mt-1'}>{this.props.msgError || ''}</div>}
                {this.props.showVerify && (
                  <>
                    <button type="button" id={this.props.buttonId} className="btn btn-wbp btn-wbp-main btn-top-right" onClick={this.props.onVerify} disabled={this.props.disabledVerify}>
                      <span>{'Verify'}</span>
                    </button>
                  </>
                )}

                {this.props.showIconCheck && (
                  <span className="form-icon form-icon-check">
                    <i className="fas fa-check text-white" />
                  </span>
                )}
              </div>

              {this.props.showIcon && (
                <span className="form-icon form-icon-eye" onClick={this.props.onClickToggleShowPassword}>
                  <i className={`${this.props.showPW ? 'far fa-eye-slash' : 'far fa-eye'}`} />
                </span>
              )}

              {this.props.showIconOther && (
                <span className="form-icon" onClick={this.props.onClickForm}>
                  <i className={`${this.props.showIconOther ? this.props.showIconOther : ''}`} />
                </span>
              )}
              {this.props.showConfirm && (
                <button id={this.props.buttonId} type="button" disabled={this.props.disabledConfirm} onClick={this.props.onConfirm} className="btn btn-wbp btn-wbp-main btn-top-right">
                  <span>{'Confirm'}</span>
                </button>
              )}
              {this.props.showResend && (
                <button
                  id={this.props.buttonId}
                  type="button"
                  className="btn btn-wbp btn-wbp-main btn-top-right"
                  onClick={(e) => this.props.onVerify(e, 'resend')}
                  disabled={this.props.disabledResend}
                >
                  <span>{'Resennd'}</span>
                </button>
              )}
              {this.props.showTextResend && (
                // <button type="button" className="btn btn-ott btn-ott-blue btn-top-right" onClick={(e) => this.props.onVerify(e, "resend")}>
                <span className={`cursor-pointer position-absolute text-right-input text-biq-primary ${this.props.showTextResend === 'disabled' ? 'disabled' : ''}`} onClick={this.props.handleReSend}>
                  {'Resennd'}
                </span>
                // </button>
              )}

              {this.props.showButton && (
                <button id={this.props.buttonId} type="button" className="btn btn-wbp btn-wbp-main btn-top-right" onClick={this.props.onClickButton} disabled={this.props.disbaleButton}>
                  <span>{this.props.nameButton}</span>
                </button>
              )}
            </div>
          </>
        ) : (
          <div className={'position-relative'}>
            <input
              id={this.props.id}
              type={this.props.type}
              name={this.props.name}
              className={`form-control form-control${this.props.originType === ' password' ? ' pr-35' : ''}${this.props.classInput ? ` ${this.props.classInput}` : ''}`}
              placeholder={this.props.placeholder}
              autoComplete={this.props.autoComplete ? this.props.autoComplete : 'off'}
              value={this.props.value}
              style={this.props.style}
              onChange={this.props.onChange}
              onKeyPress={this.props.onKeyPress}
              onKeyDown={this.props.onKeyDown}
              onKeyUp={this.props.onKeyUp}
              onBeforeInput={this.props.onBeforeInput}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              minLength={this.props.minLength}
              maxLength={this.props.maxLength}
              step={this.props.step}
              pattern={this.props.pattern}
              disabled={this.props.disabled}
              defaultValue={this.props.defaultValue}
            />

            {this.props.showIcon && (
              <span className="form-icon form-icon-eye" onClick={this.props.onClickToggleShowPassword}>
                <i className={`${this.props.showPW ? 'far fa-eye-slash' : 'far fa-eye'}`} />
              </span>
            )}

            {this.props.showIconOther && (
              <span className="form-icon" onClick={this.props.onClickForm}>
                <i className={`${this.props.showIconOther ? this.props.showIconOther : ''}`} />
              </span>
            )}
          </div>
        )}

        {this.props.descInputForm && (
          <small
            id={`${this.props.id}Help`}
            className={`form-text fs-13 mt-2${this.props.inValid ? ' text-ott-red' : ' text-ub-text-placeholder'}`}
            onClick={this.props.onClickDescInputForm}
            dangerouslySetInnerHTML={{ __html: this.props.descInputForm }}
          />
        )}

        {this.props.descInputFormOther && (
          <small id={`${this.props.id}Help`} className={`form-text fs-13 mt-2${this.props.inValid ? ' text-ott-red' : ' text-ub-text-placeholder'}`} onClick={this.props.onClickDescInputForm}>
            {this.props.descInputFormOther}
          </small>
        )}
      </div>
    );
  }
}

export default InputForm;
