import React, { Component } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './../../../styles/components/_calender_case.scss';
import moment from 'moment';
import _ from 'lodash';

export default class CalendarForm extends Component {
  constructor(props) {
    super(props);
    this.isModeReservationManagement = props.mode == 'ReservationManagement';
    this.state = {
      date: props.date || new Date(),
      toggleCalendar: this.isModeReservationManagement ? true : false,
      isSelectedDate: props.isSelectedDate,
    };
  }

  handleChangeDate = (date) => {
    this.setState({
      date,
      toggleCalendar: false,
      isSelectedDate: true,
    });
    this.props.handleSetDateChild(date);
  };

  navigateDate = (amount) => {
    const { date } = this.state;
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + amount);
    this.setState({ date: newDate });
    this.props.handleSetDateChild(newDate);
  };

  getDayName = (date) => {
    return date.toLocaleDateString('ja', { weekday: 'short' });
  };

  renderTileContent = ({ date, view }) => {
    let currentDate = moment(date).set(0, 'h').set(0, 'm').format('YYYY-MM-DD');
    const datesWithIcons = Object.keys(_.get(this.props, 'bookingCountForMonth.data', {}));

    let isMobile = window.innerWidth < 760;
    let style1 = { color: 'black', position: 'absolute', bottom: '12px', left: '36px', fontSize: '16px' };
    let style2 = { color: 'black', fontSize: '14px', marginTop: '1px' };

    if (isMobile) {
      style1 = { color: 'black', position: 'absolute', bottom: '0px', left: 'calc(50% - 10px)', fontSize: '12px' };
      style2 = { color: 'black', fontSize: '10px', marginTop: '16%' };
    }

    if (view === 'month' && datesWithIcons.find((x) => x == currentDate)) {
      const bookingCount = _.get(this.props, 'bookingCountForMonth.data', {})[currentDate].count;

      return (
        <div style={style1}>
          <span style={{ display: 'inline-flex' }}>
            {bookingCount}
            <i className="fa fa-user ml-1" aria-hidden="true" style={style2}></i>
          </span>
        </div>
      );
    }
    return null;
  };

  showCalendar = () => {
    this.setState({ toggleCalendar: true, isSelectedDate: false });
    if (this.isModeReservationManagement) {
      this.props.handleUnSelectDate();
    }
  };

  handleOnChangeMonth = ({ action, activeStartDate, value, view }) => {
    this.props.handleOnChangeMonth(activeStartDate);
  };

  render() {
    const { date, toggleCalendar } = this.state;

    return (
      <div className="w-100 d-flex justify-content-center position-relative">
        {(this.state.isSelectedDate || !this.isModeReservationManagement) && (
          <div className="arrow-calender d-flex justify-content-between w-100">
            <button className="previous-day btn text-dark d-flex flex-row align-items-center justify-content-center" onClick={() => this.navigateDate(-1)}>
              <i className="fa fa-chevron-left" aria-hidden="true"></i>
              <h4 className="previous-day-title mb-0">前日</h4>
            </button>
            <button className="d-flex align-items-center border-0 bg-white" onClick={this.showCalendar}>
              <h5 className="title-date-calender mb-0 text-center" style={{ textDecoration: 'underline', color: 'blue ' }}>{`${date.getFullYear()}/${
                date.getMonth() + 1
              }/${date.getDate()} (${this.getDayName(date)})`}</h5>
            </button>
            <button className="next-day btn text-dark d-flex flex-row align-items-center justify-content-center" onClick={() => this.navigateDate(1)}>
              <h4 className="previous-day-title mb-0 text-dark">翌日</h4>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
          </div>
        )}
        {toggleCalendar &&
          (this.isModeReservationManagement ? (
            <Calendar
              className={`calendar-reservationmanagement ${this.state.isSelectedDate ? '' : 'mt--50'}`}
              value={date}
              defaultView="month"
              locale="ja"
              calendarType="gregory"
              tileContent={this.renderTileContent}
              onChange={this.handleChangeDate}
              onActiveStartDateChange={this.handleOnChangeMonth}
            />
          ) : (
            <Calendar onChange={this.handleChangeDate} value={date} defaultView="month" locale="ja" calendarType="gregory" />
          ))}
      </div>
    );
  }
}
