const _ = require('lodash');

export const LANGUAGES = {
  SUCCESS: [0, '成功', 'Success'],
  COMMON_ERROR: [1, 'いくつかのエラーがあります。', 'There is some errors'],
  NO_PERMISSION: [403, `このAPIにアクセスする権限がありません。`, `You don't have permission to access this api`],
  TOKEN_IS_NOT_VALID_OR_EXPIRED: [401, 'トークンは無効か期限切れです。', 'Token is not valid or expired'],
  NOT_FOUND_USER: [1000, 'ユーザーが見つかりません。', 'Not found user'],
  MISSING_FIELD_OR_INVALID: [1001, `フィールド「@」が不足か無効です。`, `Missing field [@] or invalid`],
  WRONG_PASSWORD: [1002, `パスワードが正しくありません。`, `Wrong password`],
  NOT_FOUND_SALON: [1100, `店舗が見つかりません。`, `Salon not found`],
  EXIST_SALON_USER: [1101, `この店舗は既に存在します。`, `Exist salon 's user`],
  EXIST_SALON_NAME: [1102, `この店舗名は既に存在します。`, `Exist salon name`],
  EXIST_USER_NAME: [1200, `このユーザー名は既に存在します。`, `Exist user name`],
  NOT_FOUND_SCHEDULE: [1300, `シフト管理を未設定です。`, `Not found schedule`],
  OUT_OF_SCHEDULE: [1301, `シフト管理の設定範囲外です。`, `Out of Schedule`],
  CANNOT_UPDATE_SCHEDULE_BECAUSE_THE_RESERVATION_IS_ALREADY_MADE: [1302, `すでに予約が入っているため、\nシフトの更新はできません。`, `Cannot update schedule\nbecause the reservation is already made`],
  NOT_FOUND_SERVICE: [1400, `サービスが見つかりません。`, `Not found service`],
  NOT_FOUND_BOOKING: [1500, `予約がが見つかりません。`, `Not found booking`],
  BOOKING_FULL: [1501, `予約が満席です。`, `Booking full`],
  CANNOT_CANCEL_BOOKING: [1502, `予約をキャンセルできません。`, `Cannot cancel booking`],

  TEXT_PLEASE_ENTER_THE_ACCOUNT_ID: [null, `アカウントIDを入力してください。`, `Please enter the account id`],
  TEXT_PLEASE_ENTER_THE_PASSWORD: [null, `パスワードを入力してください。`, `Please enter the password`],
  TEXT_PLEASE_ENTER_LOGIN_ID_AND_OR_PASSWORD_CORRECTLY: [null, `ログインに失敗しました。入力内容に誤りがないか確認してください。`, `Please enter login ID and/or password correctly`],
  TEXT_EXIST_ACCOUNT_ID: [null, `アカウントIDは既に存在します。`, `Exist account ID`],

  TEXT_MESSAGE_FORGET_PASSWORD: [
    null,
    `ご入力いただいたアカウントIDに紐付くLINEアカウントに初期化URLのメッセージを送信いたしました。\nメッセージが届かない場合は入力されたアカウントIDに誤りがあります。\n再度お試し下さい。`,
    `A message with an initialization URL has been sent to the LINE account linked to the account ID you entered. \nIf you do not receive the message, the account ID you entered is incorrect. \nPlease try again.`,
  ],

  TEXT_PLEASE_ENTER_THE_CURRENT_PASSWORD: [null, `現在のパスワードを入力してください。`, `Please enter the current password`],
  TEXT_THE_CURRENT_PASSWORD_IS_INCORRECT: [null, `現在のパスワードが正しくありません。`, `The current password is incorrect`],
  TEXT_PLEASE_ENTER_THE_NEW_PASSWORD: [null, `新しいパスワードを入力してください。`, `Please enter the new password`],
  TEXT_CURRENT_PASSWORD_AND_NEW_PASSWORD_ARE_THE_SAME: [null, `現在のパスワードと新しいパスワードが同じです。`, `Current password and new password are the same`],
  TEXT_PASSWORD_HAVE_LESS_THAN_10_CHARACTERS: [null, `新しいパスワードは10文字以上で入力してください。`, `Password have less than 10 characters`],
  TEXT_PLEASE_ENTER_A_NEW_PASSWORD_USING_LETTERS__NUMBERS__SYMBOLS: [null, `新しいパスワードは半角英数字と記号で入力してください。`, `Please enter a new password using letters, numbers, symbols`],
  TEXT_PASSWORD_HAVE_LESS_THAN_8_CHARACTERS: [null, `新しいパスワードは8文字以上で入力してください。`, `Password have less than 8 characters`],
  TEXT_PLEASE_ENTER_A_NEW_PASSWORD_USING_LETTERS__NUMBERS: [null, `新しいパスワードは半角英数字で入力してください。`, `Please enter a new password using letters, numbers`],
  TEXT_PLEASE_ENTER_THE_CONFIRM_PASSWORD: [null, `確認パスワードを入力してください。`, `Please enter the confirm password`],
  TEXT_NEW_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH: [null, `新パスワードと確認パスワードが一致しません。`, `New password and confirm password do not match`],
  TEXT_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH: [null, `新パスワードと確認パスワードが一致しません。`, `Password and confirm password do not match`],
  TEXT_PASSWORD_HAS_BEEN_CHANGED_SUCCESSFULLY: [null, `パスワードの変更が完了しました。`, `Password has been changed successfully`],

  TEXT_PASSWORD_HAS_BEEN_RESET_SUCCESSFULLY: [null, `パスワードの再設定が完了しました。`, `Password has been reset successfully`],

  TEXT_STORE_MANAGEMENT: [null, `店舗管理`, `Store management`],
  TEXT_STORE_INFORMATION_REGISTRATION: [null, `店舗情報登録`, `Store information registration`],
  TEXT_STORE_SEARCH: [null, `店舗検索`, `Store search`],
  TEXT_NO_: [null, `順`, `No.`],
  TEXT_STORE_NAME: [null, `店舗名`, `Store name`],
  TEXT_STORE_PHONE_NUMBER: [null, `店の電話番号`, `Store phone number`],
  TEXT_PLEASE_ENTER_NUMBER: [null, `数字で入力してください。`, `Please enter number`],
  TEXT_PLEASE_ENTER_THE_STORE_NAME: [null, `店舗名を入力してください。`, `Please enter the store name`],
  TEXT_SALON_NAME_HAVE_LESS_THAN_2_CHARACTERS: [null, `店舗名は2文字未満です。`, `Salon name have less than 2 characters`],
  TEXT_SALON_NAME_HAVE_MORE_THAN_100_CHARACTERS: [null, `店舗名は100文字を超えています。`, `Salon name have more than 100 characters`],
  TEXT_PHONE_NUMBER_HAVE_LESS_THAN_10_CHARACTERS: [null, `電話番号は10桁未満です。`, `Phone number have less than 10 characters`],
  TEXT_PHONE_NUMBER_HAVE_MORE_THAN_12_CHARACTERS: [null, `電話番号は12桁を超えています。`, `Phone number have more than 12 characters`],
  TEXT_ADDRESS_HAVE_LESS_THAN_2_CHARACTERS: [null, `住所は2文字未満です。`, `Address have less than 2 characters`],
  TEXT_ADDRESS_HAVE_MORE_THAN_100_CHARACTERS: [null, `住所は100文字を超えています。`, `Address have more than 100 characters`],

  TEXT_PLEASE_ENTER_THE_PHONE_NUMBER: [null, `半角数字で入力してください。`, `Please enter the phone number`],
  TEXT_PLEASE_ENTER_THE_ADDRESS: [null, `住所を入力してください。`, `Please enter the address`],
  TEXT_EXIST_PHONE_NUMBER: [null, `この電話番号は既に存在します。`, `Exist phone number`],
  TEXT_EXIST_ADDRESS: [null, `この住所は既に存在します。`, `Exist address`],

  TEXT_PLEASE_ENTER_THE_STORE_ID: [null, `店舗IDを入力してください。`, `Please enter the store id`],
  TEXT_THE_STORE_ID_IS_INCORRECT: [null, `店舗IDが正しくありません。`, `The store id is incorrect`],
  TEXT_EXIST_STORE_ID: [null, `この店舗IDは既に存在します。`, `Exist store id`],
  TEXT_PLEASE_ENTER_THE_ACCOUNT_ID: [null, `ユーザー名を入力してください。`, `Please enter the account id`],
  TEXT_PLEASE_ENTER_LOGIN_ID_CORRECTLY: [null, `入力されたアカウントIDが登録されていません。`, `Please enter login ID correctly`],
  TEXT_PLEASE_ENTER_THE_MANAGER_NAME: [null, `店長名を入力してください。`, `Please enter the manager name`],
  TEXT_THIS_SALON_HAS_STAFF: [null, `この店舗は既に店長がが存在します。`, `This salon has staff`],
  TEXT_USER_NAME_HAVE_MORE_THAN_100_CHARACTERS: [null, `ユーザー名は100文字を超えています。`, `User name have more than 100 characters`],

  TEXT_PLEASE_ENTER_THE_USER_NAME: [null, `ご予約者氏名を入力してください。`, `Please enter the user name`],
  TEXT_PLEASE_ENTER_THE_USER_PHONE: [null, `電話番号を入力してください。`, `Please enter the user phone`],
  TEXT_PLEASE_ENTER_NUMBER: [null, `数字で入力してください。`, `Please enter number`],
  TEXT_PHONE_NUMBER_HAVE_LESS_THAN_10_CHARACTERS: [null, `電話番号は10桁未満です。`, `Phone number have less than 10 characters`],
  TEXT_PHONE_NUMBER_HAVE_MORE_THAN_12_CHARACTERS: [null, `電話番号は12桁を超えています。`, `Phone number have more than 12 characters`],
  TEXT_PLEASE_SELECT_THE_YEAR_OF_BIRTH: [null, `生年を選択してください。`, `Please select the year of birth`],
  TEXT_PLEASE_SELECT_THE_GENDER: [null, `性別を選択してください。`, `Please select the gender`],
  TEXT_PURPOSE_OF_VISIT_HAVE_MORE_THAN_200_CHARACTERS: [null, `ご来店の目的・ご要望・ご質問は200文字を超えています。`, `Purpose of visit have more than 200 characters` ],

  TEXT_PLEASE_SELECT_THE_REASON: [null, `キャンセル理由を選択してください。`, `Please select the reason`],

  TEXT_PLEASE_ENTER_THE_NUMBER_OF_SLOTS: [null, `枠数を入力してください。`, `Please enter the number of slots`],
  TEXT_PLEASE_ENTER_THE_BULK_SETTING_NUMBER_OF_SLOTS: [null, `一括設定の枠数を入力してください。`, `Please enter the bulk setting number of slots`],
  TEXT_SLOTS_HAS_BEEN_UPDATED_SUCCESSFULLY: [null, `シフトの管理が更新できました。`, `Slots has been updated successfully`],
  TEXT_SLOTS_HAS_BEEN_UPDATED_FAILED: [null, `シフトの管理が更新できませんでした。`, `Slots has been updated failed`],
  TEXT_PLEASE_ENTER_TIME_RANGE_WITH_FORMAT_FIRST_TIME_SLOT_LAST_TIME_SLOT__EX__9H00_12H00: [null, `最初の時間帯-最後の時間帯の\nフォーマットで入力してください。`, `Please enter time range with format \nfirst time slot-last time slot, ex: 9h00-12h00`],
  TEXT_THE_TIME_RANGE_YOU_SPECIFIED_IS_OUTSIDE_SALON_WORKING_TIME: [null, `指定した時間範囲は営業時間外です。`, `The time range you specified is outside salon working time`],

  TEXT_PLEASE_ENTER_THE_OPTION_CONTENTS: [null, `オプション内容を入力してください。`, `Please enter the option contents`],
  TEXT_EXIST_OPTION_CONTENTS: [null, `このオプション内容は既に存在します。`, `Exist option contents`],
  TEXT_PLEASE_ENTER_THE_PRICE: [null, `表示価格を入力してください。`, `Please enter the price`],
  TEXT_PLEASE_ENTER_THE_DISPLAY_ORDER: [null, `表示順番を入力してください。`, `Please enter the display order`],
  TEXT_CREATE_OPTION_SUCCESSFULLY: [null, `オプションの登録が完了しました。`, `Create Option Successfully`],
  TEXT_UPDATE_OPTION_SUCCESSFULLY: [null, `オプションの更新が完了しました。`, `Update Option Successfully`],
  TEXT_DELETE_OPTION_SUCCESSFULLY: [null, `オプションの削除が完了しました。`, `Delete Option Successfully`],
  TEXT_PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS_LONG_AND_INCLUDE_AT_LEAST_ONE_LETTER__ONE_NUMBER__OR_ONE_SPECIAL_CHARACTER: [
    null,
    `新しいパスワードは半角英数字8文字以上で入力してください。`,
    `Password must be at least 8 characters long and include at least one letter, one number, or one special character`,
  ],
  TEXT_PASSWORD_MUST_BE_AT_LEAST_10_CHARACTERS_LONG_AND_INCLUDE_AT_LEAST_ONE_LETTER__ONE_NUMBER__ONE_SPECIAL_CHARACTER: [
    null,
    `新しいパスワードは10文字以上で半角英数字と記号の組み合わせを入力してください。`,
    `Password must be at least 10 characters long and include at least one letter, one number, one special character`,
  ],
  TEXT_CONFIRM_PASSWORD: [null, `確認パスワード`, `Confirm Password`],
  TEXT_PASSWORD: [null, `パスワード`, `Password`],
  TEXT_USERNAME: [null, `ユーザー名(アカウントID)`, `Username`],
  TEXT_NAME: [null, `店長名`, `Name`],
  TEXT_SALON: [null, `店舗名`, `Salon`],
  TEXT_PHONE: [null, `電話番号`, `Phone`],
  TEXT_PLEASE_ENTER_NAME: [null, `店長名を入力してください。`, `Please enter name`],
  TEXT_PLEASE_SELECT_SALON: [null, `店舗名を選択してください。`, `Please select salon`],
  TEXT_LINE_USER_ID: [null, `LINEのユーザーID`, `Line User ID`],
  TEXT_LINE_TOKEN: [null, `LINEのトークン`, `Line Token`],
  TEXT_UPDATE_STAFF_SUCCESSFULLY: [null, `アカウントの更新が完了しました。`, `Update Staff Successfully`],
  TEXT_CREATE_STAFF_SUCCESSFULLY: [null, `アカウントの登録が完了しました。`, `Create Staff Successfully`],
  TEXT_UPDATE_SALON_SUCCESSFULLY: [null, `店舗の更新が完了しました。`, `Update Salon Successfully`],
  TEXT_CREATE_SALON_SUCCESSFULLY: [null, `店舗の登録が完了しました。`, `Create Salon Successfully`],
  TEXT_UPDATE_SALON_STATUS_SUCCESSFULLY: [null, `ステータスの変更が完了しました。`, `Update Salon Status Successfully`],
  TEXT_ACCOUNT_IS_NOT_ACTIVE: [null, `アカウントは非アクティブです。`, `Account is not active`],
  TEXT_CHANGE_PASSWORD_SUCCESSFULLY: [null, `パスワードの変更が完了しました。`, `Change password successfully`],
  TEXT_CAN_NOT_RESET_ADMIN_PASSWORD: [null, `admin権限のため、システム管理者にご連絡下さい！`, `Can not reset admin password`],
  TEXT_ACTIVE: [null, `有効`, `Active`],
  TEXT_INACTIVE: [null, `無効`, `Inactive`],
  TEXT_LINK_SALON_LINE_ACCOUNT_SUCCESSFULLY: [null, `店舗に店長のLINEアカウントを連携が完了しました。`, `Link Salon LINE Account successfully`],
  TEXT_SAVE_SHIFT_SUCCESSFULLY: [null, `シフトを保存しました。`, `Save Shift Successfully`],
  TEXT_CREATE_BOOKING_SUCCESSFULLY: [null, `予約が完了しました。`, `Create Booking Successfully`],
  TEXT_CANCEL_BOOKING_SUCCESSFULLY: [null, `予約のキャンセルが完了しました。`, `Cancel Booking Successfully`],
  TEXT_CREATE_SERVICE_SUCCESS: [null, `オプションの更新が完了しました。`, `Update Option Successfully`],
  TEXT_DELETE_SERVICE_SUCCESS: [null, `オプションの削除が完了しました。`, `Delete Option Successfully`],
  TEXT_BACK: [null, `メニューへ`, `Back`],
  TEXT_PLEASE_ENTER_WORKING_TIME_WITH_FORMAT_START_END: [
    null,
    `営業時間を開始時間-終了時間のフォーマットで入力してください。例: 8h00-23h00`,
    `Please enter working time with format start-end, eg: 8h00-23h00`,
  ],
  TEXT_WORKING_TIME: [null, `営業時間`, `Working time`],
  TEXT_SERVICE_PRIORITY_IS_EXIST__PLEASE_ENTER_NEW_PRIORITY: [null, `この表示順番は既に設定されております。\n別の表示順番を設定してください。`, `Service priority is exist, please enter new priority`],
  TEXT_PLEASE_ENTER_THE_PURPOSE_OF_VISIT: [null, `ご来店の目的・ご要望・ご質問を入力してください。`, `Please enter the purpose of visit` ],
};

const templateCode = '[@]';
let SELECTED_LANGUAGE = 'JP';
export const getTranslate = (language, arr) => {
  const msg = language[SELECTED_LANGUAGE == 'EN' ? 2 : 1];

  const arrParams = arr && !_.isArray(arr) ? [arr] : arr;
  if (msg.indexOf(templateCode) > -1 && _.isArray(arrParams)) {
    return msg
      .split(templateCode)
      .map((x, idx) => [x, arrParams[idx]].join(''))
      .join('');
  }

  return msg;
};

export const getTranslateByCode = (code) => {
  function getKeyByCode(code) {
    for (const [key, value] of Object.entries(LANGUAGES)) {
      if (value[0] === code) {
        return key;
      }
    }
    return null;
  }
  const type = getKeyByCode(code);
  return getTranslate(LANGUAGES[type]);
};

export const LANGUAGES_ERROR = _.keyBy(
  _.toArray(LANGUAGES).filter((x) => x[0]),
  (x) => x[0]
);

export const getError = (language, arr) => {
  const msg = getTranslate(language, arr);
  const err = new Error(msg);
  // eslint-disable-next-line prefer-destructuring
  err.error_code = language[0];

  return err;
};
