import React, { Component } from 'react';
import './../../../styles/components/listmanage.scss';
import './../../../styles/components/deletestore.scss';
import './../../../styles/components/updatestore.scss';

import _ from 'lodash';
import { connect } from 'react-redux';
import { salonList, salonCreate, TYPES } from '../../../actions';
import Pagination from '../Pagination';
import { checkValidate, LANGUAGES, getTranslate, notify } from '../../../utils';

class SalonManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salon_list: {},
      name_search: '',
      pageSize: 10,
      selectedPage: 1,
      offset: 0,
      order_by: 'created_time',
      totalPage: 0,
      formMode: 'list', // insert, update, delete
      selectedRow: null,

      form: {},
      formIsValid: false,
      msg: {},
      validates: [
        { field: 'code', type: 'required', value: true, mode: ['update'], msg: '' },
        { field: 'name', type: 'unique', value: true, msg: getTranslate(LANGUAGES.EXIST_SALON_NAME) },
        { field: 'name', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_STORE_NAME) },
        { field: 'name', type: 'max_length', value: 100, msg: getTranslate(LANGUAGES.TEXT_SALON_NAME_HAVE_MORE_THAN_100_CHARACTERS) },
        { field: 'working_time', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_WORKING_TIME_WITH_FORMAT_START_END) },
        { field: 'phone', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_PHONE_NUMBER) },
        { field: 'phone', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_PHONE_NUMBER) },
        { field: 'phone', type: 'max_length', value: 12, msg: getTranslate(LANGUAGES.TEXT_PHONE_NUMBER_HAVE_MORE_THAN_12_CHARACTERS) },
        { field: 'phone', type: 'min_length', value: 10, msg: getTranslate(LANGUAGES.TEXT_PHONE_NUMBER_HAVE_LESS_THAN_10_CHARACTERS) },
        { field: 'address', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_ADDRESS) },
      ],
      actionNotify: {
        time: +new Date(),
      },
      hiddenCards: {},
      isMobile: window.innerWidth < 769,
    };
  }

  componentDidMount() {
    this.getListSalon();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 769 });
  };

  getListSalon() {
    this.props.salonList({
      order_by: this.state.order_by,
      offset: this.state.offset,
      limit: this.state.pageSize,
      name_search: this.state.name_search || '',
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    let newState = {
      ..._.pick(props, ['salon_list']),
    };

    ['salon_list'].forEach((field) => {
      if (JSON.stringify(newState[field]) == this.state[field]) {
        delete newState[field];
      }
    });

    if (!_.isEmpty(newState)) {
      if (newState.salon_list) {
        newState.isLoadingData = false;
        newState.totalPage = Math.ceil((props.salon_list.total_record || 0) / this.state.pageSize);
      }
      this.setState(newState);
    }

    if (!_.isEmpty(props.actionNotify) && props.actionNotify.time > this.state.actionNotify.time) {
      switch (props.actionNotify.type) {
        case TYPES.ACTION_SALON_SAVE_SUCCESS:
          if (this.state.formMode == 'insert') {
            notify(getTranslate(LANGUAGES.TEXT_CREATE_SALON_SUCCESSFULLY), 'success');
          } else if (this.state.formMode == 'update') {
            notify(getTranslate(LANGUAGES.TEXT_UPDATE_SALON_SUCCESSFULLY), 'success');
          } else if (['active', 'inactive'].indexOf(this.state.formMode) > -1) {
            notify(getTranslate(LANGUAGES.TEXT_UPDATE_SALON_STATUS_SUCCESSFULLY), 'success');
          }
          this.handleChangeFormMode('list', null);
          this.getListSalon();

          break;

        case TYPES.ACTION_CALL_API_FAIL:
          if (props.actionNotify.error_code == 1102) {
            this.setState({
              msg: {
                ...this.state.msg,
                name: {
                  uniq: getTranslate(LANGUAGES.EXIST_SALON_NAME),
                },
              },
            });
          }

        default:
          break;
      }
      this.setState({
        actionNotify: props.actionNotify,
      });
    }
  }

  onSelectedPage = (page) => {
    this.setState(
      {
        selectedPage: page,
        offset: (page - 1) * this.state.pageSize,
      },
      () => {
        this.getListSalon();
      }
    );
  };

  onChangeSearch = (e, field) => {
    const value = e.target.value;
    this.setState(
      {
        [field]: value,
      },
      () => {
        if (field == 'name_search' && !value) {
          this.getListSalon();
        }
      }
    );
  };

  handleSearch = () => {
    let page = 1;
    this.setState(
      {
        selectedPage: page,
        offset: (page - 1) * this.state.pageSize,
      },
      () => {
        this.getListSalon();
      }
    );
  };
  onEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };

  onChangeFormField = (e, field) => {
    let value = e.target.value;
    if (field == 'phone') {
      value = value.replace(/\D/g, '').substr(0, 12);
    }
    let newForm = {
      ...this.state.form,
      [field]: value,
    };
    let validates = this.state.validates.filter((x) => !x.mode || x.mode.indexOf(this.state.formMode) > -1);
    let msg = checkValidate(validates, newForm);
    let formIsValid = _.isEmpty(msg);
    if (this.state.formMode == 'insert') {
      this.setState({
        form: newForm,
        msg: {},
        formIsValid: true,
      });
    } else {
      this.setState({
        form: newForm,
        msg,
        formIsValid,
      });
    }
  };

  handleChangeFormMode = (mode, row) => {
    console.log('handleChangeFormMode', mode, row);
    this.setState(
      {
        formMode: mode,
        selectedRow: row,
        form: row
          ? JSON.parse(JSON.stringify(row))
          : {
              working_time: '8h00-23h00',
            },
        msg: {},
        formIsValid: true,
      },
      () => {
        if (mode === 'update') {
          this.scrollToTop();
        }
      }
    );
  };
  scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  handleSaveForm = () => {
    let validates = this.state.validates.filter((x) => !x.mode || x.mode.indexOf(this.state.formMode) > -1);
    let msg = checkValidate(validates, this.state.form);

    let working_time = _.get(this.state.form, 'working_time', '');
    if (!msg.working_time && !/\b\d{1,2}h\d{2}-\d{1,2}h\d{2}\b/.test(working_time)) {
      msg.working_time = {
        ...(msg.working_time || {}),
        wrong_format: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_WORKING_TIME_WITH_FORMAT_START_END),
      };
    }

    let formIsValid = _.isEmpty(msg);
    this.setState({
      msg,
      formIsValid,
    });
    if (formIsValid) {
      this.props.salonCreate(this.state.form);
    }
  };

  handleConfirmSalonStatus = () => {
    this.props.salonCreate({
      ...this.state.selectedRow,
      status: this.state.formMode == 'inactive' ? 1 : 0,
    });
    this.isChangeStatus = true;
  };

  toggleCardVisibility = (id) => {
    this.setState((prevState) => ({
      hiddenCards: {
        ...prevState.hiddenCards,
        [id]: !prevState.hiddenCards[id],
      },
    }));
  };

  render() {
    let { isMobile, hiddenCards } = this.state;
    return (
      <div style={{ maxWidth: 1200 }} className="container-fluid salon-management">
        {/* ===== mode list begin ===== */}
        {this.state.formMode == 'list' && (
          <div style={{ display: 'flex' }} className="list-it">
            <h1 style={{ color: 'black', fontSize: '26px' }}>店舗管理</h1>
            <button className="btn1 btn-success btn-custom-sm" style={{ fontSize: '22px', maxWidth: 700, minHeight: 42 }} onClick={() => this.handleChangeFormMode('insert')}>
              店舗情報登録
            </button>

            <div className="search-row" style={{ maxWidth: 700 }}>
              <input
                className="input-custom-sm"
                style={{ height: 42, width: '80%' }}
                type="text"
                placeholder="キーワード検索"
                value={this.state.name_search}
                onChange={(e) => this.onChangeSearch(e, 'name_search')}
                onKeyDown={this.onEnter}
              />
              <button style={{ height: 42, width: '15%', minWidth: 42 }} className="btn2 btn-success" onClick={this.handleSearch}>
                <i className="fas fa-search"></i>
              </button>
            </div>

            <div className="list-row w-100 mt-20 d-flex justify-content-center">
              {!isMobile ? (
                <table className="table table-custom table-sm table-bordered mt-4">
                  <thead className="bg-success">
                    <tr className="tb-row1">
                      <th className="w-5" style={{ maxWidth: 70 }}>
                        順
                      </th>
                      <th className="w-15" style={{ maxWidth: 250 }}>
                        店舗名
                      </th>
                      <th className="w-15" style={{ maxWidth: 200 }}>
                        店長名
                      </th>
                      <th className="w-8" style={{ maxWidth: 200 }}>
                        店の電話番号
                      </th>
                      <th className="w-10" style={{ maxWidth: 200 }}>
                        {getTranslate(LANGUAGES.TEXT_WORKING_TIME)}
                      </th>
                      <th className="w-16" style={{ maxWidth: 250 }}>
                        住所
                      </th>

                      <th className="w-17" style={{ maxWidth: 250 }}>
                        店舗ID
                      </th>
                      <th className="w-5" style={{ maxWidth: 70 }}>
                        変更
                      </th>
                      <th className="w-9" style={{ maxWidth: 70 }}>
                        ステータス
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {_.get(this.state, 'salon_list.data', []).map((salon, sIdx) => (
                      <tr key={sIdx}>
                        <td
                          style={{
                            textAlign: 'center',
                            verticalAlign: 'middle',
                          }}
                        >
                          {(this.state.selectedPage - 1) * this.state.pageSize + sIdx + 1}
                        </td>
                        <td>
                          <i
                            className="fas fa-light fa-store"
                            style={{
                              opacity: salon.line_token ? 1 : 0.5,
                              marginRight: '10px',
                            }}
                          ></i>
                          {salon.name}
                        </td>
                        <td>{salon.owner}</td>
                        <td>{salon.phone}</td>
                        <td>{salon.working_time || ''}</td>
                        <td>{salon.address}</td>
                        <td>{salon.code}</td>
                        <td style={{ cursor: 'pointer', textAlign: 'center' }} onClick={() => this.handleChangeFormMode('update', salon)}>
                          <i className="fas fa-edit table-td_icon"></i>
                        </td>
                        <td style={{ cursor: 'pointer', textAlign: 'center' }} onClick={() => this.handleChangeFormMode(salon.status ? 'active' : 'inactive', salon)}>
                          <i className={`fas ${salon.status == 1 ? 'fa-toggle-off' : 'fa-toggle-on'} table-td_icon`}></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="w-100">
                  {_.get(this.state, 'salon_list.data', []).map((salon, sIdx) => (
                    <div key={sIdx} className={`card card-custom-ms text-dark w-100 card-optionmanage`}>
                      <div className="card-body">
                        <div className="card-content">
                          <div className="card-list">
                            <h4 className="card-title card-name">店舗名</h4>
                            <p className="card-text">
                              <i
                                className="fas fa-light fa-store"
                                style={{
                                  opacity: salon.line_token ? 1 : 0.5,
                                  marginRight: '10px',
                                }}
                              ></i>
                              {salon.name}
                            </p>
                          </div>
                          {!hiddenCards[salon.id] && (
                            <div>
                              <div className="card-list">
                                <h4 className="card-title card-owner">店長名</h4>
                                <p className="card-text">{salon.owner}</p>
                              </div>

                              <div className="card-list">
                                <h4 className="card-title card-phone">店の電話番号</h4>
                                <p className="card-text">{salon.phone}</p>
                              </div>

                              <div className="card-list">
                                <h4 className="card-title card-working_time">営業時間</h4>
                                <p className="card-text">{salon.working_time || ''}</p>
                              </div>

                              <div className="card-list">
                                <h4 className="card-title card-address">住所</h4>
                                <p className="card-text">{salon.address}</p>
                              </div>

                              <div className="card-list">
                                <h4 className="card-title card-code">店舗ID</h4>
                                <p className="card-text">{salon.code}</p>
                              </div>

                              <div className="card-list card-list-edit">
                                <h4 className="card-title card-title-icon">アクション</h4>
                                <div className="card-list-icon">
                                  <i style={{ cursor: 'pointer' }} className="fas fa-edit card-icon" onClick={() => this.handleChangeFormMode('update', salon)}></i>
                                  <i
                                    className={`fas ${salon.status == 1 ? 'fa-toggle-off' : 'fa-toggle-on'} card-icon`}
                                    onClick={() => this.handleChangeFormMode(salon.status ? 'active' : 'inactive', salon)}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* <div className="card-toggle">
                            <button onClick={() => this.toggleCardVisibility(salon.id)} className="btn btn-card-toggle">
                              {!hiddenCards[salon.id] ? '情報を表示' : '情報を隠す'}
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <nav>
              <Pagination totalPage={this.state.totalPage} onClickPage={this.onSelectedPage} activeKey={this.state.selectedPage}></Pagination>
            </nav>
          </div>
        )}
        {/* ===== mode end begin ===== */}

        {/* ===== mode active & deactivate begin ===== */}
        {['active', 'inactive'].indexOf(this.state.formMode) > -1 && (
          <div style={{ top: 0, left: 0, backgroundColor: '#f2f2f2' }} className="d-flex justify-content-center position-fixed w-100 h-100 flex-column align-items-center">
            <div
              id="deletestore"
              style={{ display: 'flex', maxWidth: 700, width: '100%', height: 'fit-content', backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '0.25rem' }}
              className="bg px-40 py-20 position-relative"
            >
              <div className="contain-main d-flex flex-column align-items-center p-0">
                <h1 style={{ color: 'black', fontSize: '26px' }}>
                  {
                    {
                      active: '店舗有効',
                      inactive: '店舗無効',
                    }[this.state.formMode]
                  }
                </h1>
                <p style={{ color: 'black', fontSize: '16px' }}>
                  {
                    {
                      active: '有効すると以下の情報が全て活性化されます',
                      inactive: '無効すると以下の情報が全て不活性化されます',
                    }[this.state.formMode]
                  }
                </p>
                <div className="content-main w-100 m-0" style={{ padding: '1.25rem', minHeight: '1px', flex: '1 1 auto' }}>
                  <li className="d-flex flex-wrap" style={{ fontSize: '16px' }}>
                    <h5 style={{ margin: '2px 0 0 0', fontSize: '16px', minWidth: 70 }}>・店舗名：</h5>
                    {_.get(this.state, 'selectedRow.name', '')}
                  </li>

                  <li className="d-flex flex-wrap" style={{ fontSize: '16px' }}>
                    <h5 style={{ margin: '2px 0 0 0', fontSize: '16px', minWidth: 115 }}>・店の電話番号：</h5> {_.get(this.state, 'selectedRow.phone', '')}
                  </li>
                  <li className="d-flex flex-wrap" style={{ fontSize: '16px' }}>
                    <h5 style={{ margin: '2px 0 0 0', fontSize: '16px', minWidth: 100 }}>・{getTranslate(LANGUAGES.TEXT_WORKING_TIME)}：</h5> {_.get(this.state, 'selectedRow.working_time', '')}
                  </li>
                  <li className="d-flex flex-wrap" style={{ fontSize: '16px' }}>
                    <h5 style={{ margin: '2px 0 0 0', fontSize: '16px', minWidth: 70 }}>・住所：</h5> {_.get(this.state, 'selectedRow.address', '')}
                  </li>
                  <li className="d-flex flex-wrap" style={{ fontSize: '16px' }}>
                    <h5 style={{ margin: '2px 0 0 0', fontSize: '16px', minWidth: 85 }}>・店舗ID：</h5> {_.get(this.state, 'selectedRow.code', '')}
                  </li>
                  <li className="d-flex flex-wrap" style={{ fontSize: '16px' }}>
                    <h5 style={{ margin: '2px 0 0 0', fontSize: '16px', minWidth: 80 }}>・店長名: </h5> {_.get(this.state, 'selectedRow.owner', '')}
                  </li>
                </div>
                <div className="d-flex justify-content-between mt-20 w-100">
                  <button className="btn btn-light font-weight-bold" style={{ border: '1px solid', minWidth: 150, fontSize: 22 }} type="submit" onClick={() => this.handleChangeFormMode('list', null)}>
                    キャンセル
                  </button>
                  <button className="btn btn-success font-weight-bold" style={{ minWidth: 120, fontSize: 22 }} type="button" onClick={() => this.handleConfirmSalonStatus()}>
                    {
                      {
                        active: '有効',
                        inactive: '無効',
                      }[this.state.formMode]
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* ===== mode active & inactive end ===== */}

        {/* ===== mode update and insert begin ===== */}
        {['update', 'insert'].indexOf(this.state.formMode) > -1 && (
          <div className="w-100 h-100 d-flex justify-content-center">
            <div id="updatestore" className="position-absolute top-0 left-0 w-100 align-items-center container-fluid" style={{ display: 'flex' }}>
              <h1 className="title-update_header" style={{ color: 'black', fontSize: '26px' }}>
                店舗情報登録
              </h1>
              <form className="update-form" style={{ maxWidth: 700, width: '100%' }}>
                {[
                  { field: 'code', jp: '店舗ID', notViewIn: 'insert', readonly: true },
                  { field: 'name', jp: '店舗名', require: true },
                  { field: 'phone', jp: '店の電話番号', require: true },
                  { field: 'working_time', jp: getTranslate(LANGUAGES.TEXT_WORKING_TIME), require: true },
                  { field: 'address', jp: '住所', require: true },
                  { field: 'line_user_id', jp: getTranslate(LANGUAGES.TEXT_LINE_USER_ID) },
                  { field: 'line_token', jp: getTranslate(LANGUAGES.TEXT_LINE_TOKEN) },
                ]
                  .filter((x) => !x.notViewIn || (x.notViewIn && x.notViewIn != this.state.formMode))
                  .map((d) => {
                    let isError = !!_.get(this.state, ['msg', d.field]);
                    return (
                      <div key={d.field}>
                        <div className="d-flex flex-row text-center">
                          <p style={{ color: 'black', fontSize: '16px',fontWeight:"bold", float: 'left', marginBottom: '.5rem' }}>{d.jp}</p>
                          <span style={{ fontSize: '16px' }} className={`span-req ${d.require ? 'req' : ''}`}>{`${d.require ? '必須' : '任意'}`}</span>
                        </div>

                        <input
                          style={{ width: '100%' }}
                          className={`${isError ? 'error-input' : ''}`}
                          placeholder={d.field == 'working_time' ? '8h00-23h00' : ''}
                          type="text"
                          readOnly={d.readonly}
                          value={_.get(this.state, ['form', d.field], '') || ''}
                          onChange={(e) => this.onChangeFormField(e, d.field)}
                        />
                        <br />
                        {isError && (
                          <>
                            {Object.keys(this.state.msg[d.field]).map((fe) => (
                              <>
                                <span style={{ color: 'red' }}>{this.state.msg[d.field][fe]}</span>
                                <br />
                              </>
                            ))}
                          </>
                        )}

                        <br />
                      </div>
                    );
                  })}
              </form>
              <div className="row-btn" style={{ maxWidth: 700, padding: 0 }}>
                <button style={{ border: '1px solid', minWidth: 150, fontSize: 22 }} type="button" className="btn btn-light font-weight-bold" onClick={() => this.handleChangeFormMode('list', null)}>
                  キャンセル
                </button>
                <button style={{ minWidth: 120, fontSize: 22 }} type="button" className={`btn font-weight-bold ${this.state.formIsValid ? 'btn-success' : 'btn-cancel'}`} onClick={this.handleSaveForm}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
        {/* ===== mode update and insert end ===== */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { salon_list, actionNotify } = state.salonReducer;

  return {
    salon_list,
    actionNotify,
  };
};

export default connect(mapStateToProps, { salonList, salonCreate })(SalonManagement);
