import React, { Component } from 'react'
import ReservationManagement from './ReservationManagement'
import { Helmet } from 'react-helmet';
export default class ReservationMain extends Component {
    render() {
        return (
            <>
                {/* <Helmet>
                    <title>Salon</title>
                </Helmet> */}
                <div className='container-fluid'>
                    <h3 className='title-case text-dark text-center mb-20' style={{fontSize:"26px"}}>予約管理</h3>
                    <ReservationManagement />
                </div>

            </>
        )
    }
}
