import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { TYPES } from '../../actions';
import _ from 'lodash';
import moment from 'moment';
import $ from 'jquery';
import querystring from 'querystring';

import { getUserInfo, refreshToken } from '../../actions';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      msg: {},
      actionNotify: {
        time: +new Date(),
      },
    };
  }

  componentDidMount() {
    console.log('Home');
    this.props.getUserInfo();
    // socketService.socket.on('message', (msg) => {
    //   console.log('got message', msg);
    //   const frame_chassis = _.get(msg, 'data[0].frame_chassis', null);
    //   switch (msg.type) {
    //     case 'updated_car_data':
    //       this.props.updatedCarData(msg.data[0]);
    //       break;
    //     case 'updated_many_car_data':
    //       this.getListRecentCarData();
    //       break;

    //     case 'show_car_data':
    //       const frameCassis = _.get(msg, 'data[0].frame_chassis', '');
    //       if (frameCassis) {
    //         window.location.href = `${window.location.origin}?frame_chassis=${frameCassis}`;
    //       }

    //       break;

    //     default:
    //       break;
    //   }
    // });
  }

  UNSAFE_componentWillReceiveProps(props) {
    let newState = _.pick(props, ['userInfo', 'actionNotify']);

    ['userInfo', 'actionNotify'].forEach((field) => {
      if (JSON.stringify(newState[field]) == this.state[field]) {
        delete newState[field];
      }
    });

    if (!_.isEmpty(newState)) {
      this.setState(newState, () => {
        let isRoleAdmin = _.get(this.state, 'userInfo.role') === 0;
        let isRoleSalon = _.get(this.state, 'userInfo.role') === 1;

        if (isRoleAdmin) {
          window.location.href = '/admin/menu';
          return;
        }
        if (isRoleSalon) {
          window.location.href = '/store/menu';
          return;
        }
      });
    }
  }


  render() {
    return <div className="container-fluid px-lg-50 home-page">Home Page</div>;
  }
}

function mapStateToProps(state) {
  const { userReducer } = state;

  return {
    actionNotify: userReducer.actionNotify,
    userInfo: userReducer.userInfo,
  };
}

export default connect(mapStateToProps, { getUserInfo })(Home);
