import React, { Component } from 'react';
import './../../../styles/components/listmanage.scss';

import _ from 'lodash';
import { connect } from 'react-redux';
import { salonList, staffList, staffCreate, TYPES } from '../../../actions';
import Pagination from '../Pagination';
import moment from 'moment';
import { checkValidate, LANGUAGES, getTranslate, notify } from '../../../utils';

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salon_list: {},
      staff_list: {},

      name_search: '',
      order_by: 'created_time',
      pageSize: 10,
      selectedPage: 1,
      offset: 0,
      totalPage: 0,
      formMode: 'list', // insert, update, delete
      selectedRow: null,

      form: {},
      formIsValid: false,
      msg: {},
      validates: [
        { field: 'salon_id', type: 'unique', value: true, msg: getTranslate(LANGUAGES.TEXT_THIS_SALON_HAS_STAFF) },
        { field: 'salon_id', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_SELECT_SALON) },
        { field: 'name', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_MANAGER_NAME) },
        { field: 'name', type: 'max_length', value: 100, msg: getTranslate(LANGUAGES.TEXT_SALON_NAME_HAVE_MORE_THAN_100_CHARACTERS) },
        { field: 'phone', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_PHONE_NUMBER) },
        { field: 'phone', type: 'max_length', value: 12, msg: getTranslate(LANGUAGES.TEXT_PHONE_NUMBER_HAVE_MORE_THAN_12_CHARACTERS) },
        { field: 'phone', type: 'min_length', value: 10, msg: getTranslate(LANGUAGES.TEXT_PHONE_NUMBER_HAVE_LESS_THAN_10_CHARACTERS) },
        { field: 'username', type: 'unique', value: true, msg: getTranslate(LANGUAGES.EXIST_SALON_USER) },
        { field: 'username', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_ACCOUNT_ID) },
        { field: 'username', type: 'max_length', value: 100, msg: getTranslate(LANGUAGES.TEXT_USER_NAME_HAVE_MORE_THAN_100_CHARACTERS) },
        { field: 'password', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_PASSWORD) },
        { field: 'password', type: 'min_length', value: 8, msg: getTranslate(LANGUAGES.TEXT_PASSWORD_HAVE_LESS_THAN_8_CHARACTERS) },
        {
          field: 'password',
          type: 'must_has_number_and_character',
          value: 8,
          msg: getTranslate(LANGUAGES.TEXT_PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS_LONG_AND_INCLUDE_AT_LEAST_ONE_LETTER__ONE_NUMBER__OR_ONE_SPECIAL_CHARACTER),
        },
        { field: 'confirm_password', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_CONFIRM_PASSWORD) },
        { field: 'confirm_password', type: 'match', value: true, msg: getTranslate(LANGUAGES.TEXT_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH) },
      ],
      actionNotify: {
        time: +new Date(),
      },
      hiddenCards: {},
      isMobile: window.innerWidth < 769,
    };

    this.regexCheckPassword = /^(?=.*[A-Za-z@#$%^&*()_\-+])(?=.*\d)[A-Za-z\d@#$%^&*()_\-+]{8,}$/;
  }

  componentDidMount() {
    this.getListStaff();
    this.getListSalon();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 769 });
  };

  getListSalon() {
    this.props.salonList({
      offset: 0,
      limit: 100000,
      name_search: '',
    });
  }
  getListStaff() {
    this.props.staffList({
      order_by: this.state.order_by,
      offset: this.state.offset,
      limit: this.state.pageSize,
      name_search: this.state.name_search || '',
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    let newState = {
      ..._.pick(props, ['salon_list', 'staff_list']),
    };

    ['salon_list', 'staff_list'].forEach((field) => {
      if (JSON.stringify(newState[field]) == this.state[field]) {
        delete newState[field];
      }
    });

    if (!_.isEmpty(newState)) {
      if (newState.staff_list) {
        newState.isLoadingData = false;
        newState.totalPage = Math.ceil((props.staff_list.total_record || 0) / this.state.pageSize);
      }
      this.setState(newState);
    }

    if (!_.isEmpty(props.actionNotify) && props.actionNotify.time > this.state.actionNotify.time) {
      switch (props.actionNotify.type) {
        case TYPES.ACTION_STAFF_UPDATE_INFO_SUCCESS:
          if (this.state.formMode == 'insert') {
            notify(getTranslate(LANGUAGES.TEXT_CREATE_STAFF_SUCCESSFULLY), 'success');
          } else if (this.state.formMode == 'update') {
            notify(getTranslate(LANGUAGES.TEXT_UPDATE_STAFF_SUCCESSFULLY), 'success');
          }
          this.handleChangeFormMode('list', null);
          this.getListStaff();
          this.getListSalon();

          break;

        case TYPES.ACTION_CALL_API_FAIL:
          switch (props.actionNotify.error_code) {
            case 1102:
              this.setState({
                msg: {
                  ...this.state.msg,
                  name: {
                    uniq: getTranslate(LANGUAGES.EXIST_SALON_NAME),
                  },
                },
              });
              notify(getTranslate(LANGUAGES.EXIST_SALON_NAME), 'error');

              break;
            case 1101:
              this.setState({
                msg: {
                  ...this.state.msg,
                  username: {
                    unique: getTranslate(LANGUAGES.EXIST_SALON_USER),
                  },
                },
              });
              notify(getTranslate(LANGUAGES.EXIST_SALON_USER), 'error');

              break;
            case 1200:
              this.setState({
                msg: {
                  ...this.state.msg,
                  username: {
                    unique: getTranslate(LANGUAGES.EXIST_USER_NAME),
                  },
                },
              });
              notify(getTranslate(LANGUAGES.EXIST_USER_NAME), 'error');

              break;
            default:
              break;
          }

        default:
          break;
      }
      this.setState({
        actionNotify: props.actionNotify,
      });
    }
  }

  onSelectedPage = (page) => {
    this.setState(
      {
        selectedPage: page,
        offset: (page - 1) * this.state.pageSize,
      },
      () => {
        this.getListSalon();
        this.getListStaff();
      }
    );
  };

  handlePageChange = (event, pageNumber) => {
    event.preventDefault();
    this.setState({ currentPage: pageNumber });
  };

  onChangeSearch = (e, field) => {
    const value = e.target.value;
    this.setState(
      {
        [field]: value,
      },
      () => {
        if (field == 'name_search' && !value) {
          this.getListStaff();
        }
      }
    );
  };

  handleSearch = () => {
    let page = 1;
    this.setState(
      {
        selectedPage: page,
        offset: (page - 1) * this.state.pageSize,
      },
      () => {
        this.getListStaff();
      }
    );
  };
  onEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };

  onChangeFormField = (e, field) => {
    let value = e.target.value;
    if (field == 'phone') {
      value = value.replace(/\D/g, '').substr(0, 12);
    }
    let newForm = {
      ...this.state.form,
      [field]: value,
    };
    let isFormInsert = this.state.formMode == 'insert';
    let isFormUpdate = this.state.formMode == 'update';

    let validates = this.state.validates.filter((x) => !x.mode || x.mode.indexOf(this.state.formMode) > -1);
    if (isFormUpdate && !_.get(newForm, 'password') && !_.get(newForm, 'confirm_password')) {
      validates = validates.filter((x) => ['password', 'confirm_password'].indexOf(x.field) == -1);
    }

    let msg = checkValidate(validates, newForm);
    let formIsValid = _.isEmpty(msg);
    if (isFormInsert) {
      let regex = this.regexCheckPassword;
      let msgError = !newForm.password || !regex.test(newForm.password) ? this.state.validates.find((x) => x.type == 'must_has_number_and_character' && x.field == 'password').msg : '';
      if (msgError) {
        msg.password = {
          ...msg.password,
          must_has_number_and_character: msgError,
        };
      }

      msgError = !newForm.confirm_password || newForm.password != newForm.confirm_password ? this.state.validates.find((x) => x.type == 'match' && x.field == 'confirm_password').msg : '';
      if (msgError) {
        msg.confirm_password = {
          ...msg.confirm_password,
          match: msgError,
        };
      }

      formIsValid = _.isEmpty(msg);
      this.setState({
        form: newForm,
        msg: {},
        formIsValid: true,
      });
    } else {
      this.setState({
        form: newForm,
        msg,
        formIsValid,
      });
    }
  };

  handleChangeFormMode = (mode, row) => {
    console.log('handleChangeFormMode', mode, row);

    let isMockData = false;

    this.setState(
      {
        formMode: mode,
        selectedRow: row,
        form: row
          ? JSON.parse(JSON.stringify(row))
          : isMockData
          ? {
              password: '12345678',
              confirm_password: '12345678',
              name: 'user salon 11',
              username: 'user_salon11',
              phone: '0123456789',
              salon_id: 1,
            }
          : {},
        msg: {},
        formIsValid: true,
      },
      () => {
        if (mode === 'update') {
          this.scrollToTop();
        }
      }
    );
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  handleSaveForm = () => {
    let isFormInsert = this.state.formMode == 'insert';
    let isFormUpdate = this.state.formMode == 'update';

    let validates = this.state.validates.filter((x) => !x.mode || x.mode.indexOf(this.state.formMode) > -1);
    if (isFormUpdate && !_.get(this.state.form, 'password') && !_.get(this.state.form, 'confirm_password')) {
      validates = validates.filter((x) => ['password', 'confirm_password'].indexOf(x.field) == -1);
    }

    let msg = checkValidate(validates, this.state.form);
    let formIsValid = _.isEmpty(msg);
    if (isFormInsert) {
      let regex = this.regexCheckPassword;
      let msgError = !this.state.form.password || !regex.test(this.state.form.password) ? this.state.validates.find((x) => x.type == 'must_has_number_and_character' && x.field == 'password').msg : '';
      if (msgError) {
        msg.password = {
          ...msg.password,
          must_has_number_and_character: msgError,
        };
      }

      msgError =
        !this.state.form.confirm_password || this.state.form.password != this.state.form.confirm_password
          ? this.state.validates.find((x) => x.type == 'match' && x.field == 'confirm_password').msg
          : '';

      if (msgError) {
        msg.confirm_password = {
          ...msg.confirm_password,
          match: msgError,
        };
      }

      formIsValid = _.isEmpty(msg);
    }
    this.setState({
      msg,
      formIsValid,
    });

    if (formIsValid) {
      this.props.staffCreate(this.state.form);
    }
  };

  handleConfirmSalonStatus = () => {
    this.props.staffCreate({
      ...this.state.selectedRow,
      status: this.state.formMode == 'inactive' ? 1 : 0,
    });
  };

  toggleCardVisibility = (id) => {
    this.setState((prevState) => ({
      hiddenCards: {
        ...prevState.hiddenCards,
        [id]: !prevState.hiddenCards[id],
      },
    }));
  };

  render() {
    let { isMobile, hiddenCards } = this.state;
    let isFormInsert = this.state.formMode == 'insert';
    let isFormUpdate = this.state.formMode == 'update';
    let lstStaff = _.get(this.state, 'staff_list.data', []);
    let lstSalon = _.get(this.state, 'salon_list.data', []);
    let selectedSalonId = _.get(this.state, 'selectedRow.salon_id');
    if (isFormInsert || isFormUpdate) {
      lstSalon = lstSalon.filter((x) => (isFormInsert && !lstStaff.find((y) => y.salon_id == x.id)) || (isFormUpdate && (selectedSalonId == x.id || !lstStaff.find((y) => y.salon_id == x.id))));
    }
    return (
      <div className="container-fluid d-flex justify-content-center">
        {/* ===== mode list begin ===== */}
        {this.state.formMode == 'list' && (
          <div style={{ display: 'flex', maxWidth: 1200 }} className="list-it w-100">
            <h1 style={{ color: 'black', fontSize: '26px' }}>アカウント管理</h1>

            <button className="btn1 btn-success btn-custom-sm" style={{ minHeight: 42, maxWidth: 700 }} onClick={() => this.handleChangeFormMode('insert')}>
              アカウント登録
            </button>

            <div className="search-row" style={{ maxWidth: 700 }}>
              <input
                className="input-custom-sm"
                style={{ height: 42, width: '80%' }}
                type="text"
                placeholder="キーワード検索"
                value={this.state.name_search}
                onChange={(e) => this.onChangeSearch(e, 'name_search')}
                onKeyDown={this.onEnter}
              />
              <button className="btn2 btn-success" style={{ height: 42, width: '15%', minWidth: 42 }} onClick={this.handleSearch}>
                <i className="fas fa-search"></i>
              </button>
            </div>

            <div className="list-row w-100 mt-20 d-flex justify-content-center">
              {!isMobile ? (
                <table className="table table-account-management table-custom table-bordered mt-4" style={{ maxWidth: 1360 }}>
                  <thead className="bg-success">
                    <tr className="tb-row1">
                      <th style={{ maxWidth: 70, padding: '0.75rem 0.15rem' }} className="num w-5">
                        順
                      </th>
                      <th style={{ maxWidth: 300, padding: '0.75rem 0.15rem' }} className="w-22">
                        店舗名
                      </th>
                      <th style={{ maxWidth: 250, padding: '0.75rem 0.15rem' }} className="w-20">
                        店長名
                      </th>
                      <th style={{ maxWidth: 220, padding: '0.75rem 0.1rem' }} className="w-20">
                        ユーザー名(アカウントID)
                      </th>
                      <th style={{ maxWidth: 200, padding: '0.75rem 0.15rem' }} className="w-13">
                        店長の電話番号
                      </th>
                      <th style={{ maxWidth: 150, padding: '0.75rem 0.15rem' }} className="w-8">
                        登録日
                      </th>
                      <th style={{ maxWidth: 120, padding: '0.75rem 0.15rem' }} className="w-12">
                        ステータス
                      </th>
                      <th style={{ maxWidth: 70, padding: '0.75rem 0.15rem' }} className="w-5">
                        変更
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.get(this.state, 'staff_list.data', []).map((user, sIdx) => (
                      <tr className="font-weight-bold" key={user.id}>
                        <td style={{ textAlign: 'center', fontSize: '16px' }}>{(this.state.selectedPage - 1) * this.state.pageSize + sIdx + 1}</td>
                        <td style={{ fontSize: '16px' }}>{user.salon_name}</td>
                        <td style={{ fontSize: '16px' }}>
                          <span>
                            <i
                              className="fas fa-light fa-user"
                              style={{
                                opacity: user.line_user_id ? 1 : 0.5,
                                marginRight: '10px',
                              }}
                            ></i>
                            {user.name}
                          </span>
                        </td>
                        <td style={{ fontSize: '16px' }}>{user.username}</td>
                        <td style={{ fontSize: '16px' }}>{user.phone}</td>
                        <td style={{ fontSize: '16px' }} className="text-center">
                          {moment(user.created_time).format('YYYY/MM/DD')}
                        </td>
                        <td className={user.status ? 'text-warning' : 'text-success'} style={{ textAlign: 'center', fontSize: '16px' }}>
                          {user.status ? getTranslate(LANGUAGES.TEXT_INACTIVE) : getTranslate(LANGUAGES.TEXT_ACTIVE)}
                        </td>
                        <td style={{ cursor: 'pointer', textAlign: 'center' }} onClick={() => this.handleChangeFormMode('update', user)}>
                          <i className="fas fa-edit table-td_icon"></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="w-100">
                  {_.get(this.state, 'staff_list.data', []).map((user, sIdx) => (
                    <div key={sIdx} className={`card card-custom-ms text-dark w-100 card-optionmanage`}>
                      <div className="card-body">
                        <div className="card-content">
                          <div className="card-list">
                            <h4 className="card-title card-salon_name">店舗名</h4>
                            <p className="card-text">{user.salon_name}</p>
                          </div>
                          {!hiddenCards[user.id] && (
                            <div>
                              <div className="card-list">
                                <h4 className="card-title card-name">店長名</h4>
                                <p className="card-text">
                                  <span>
                                    <i
                                      className="fas fa-light fa-user"
                                      style={{
                                        opacity: user.line_user_id ? 1 : 0.5,
                                        marginRight: '10px',
                                      }}
                                    ></i>
                                    {user.name}
                                  </span>
                                </p>
                              </div>

                              <div className="card-list">
                                <h4 className="card-title card-username">ユーザー名<br></br>(アカウントID)</h4>
                                <p className="card-text">{user.username}</p>
                              </div>

                              <div className="card-list">
                                <h4 className="card-title card-phone">店長の電話番号</h4>
                                <p className="card-text">{user.phone}</p>
                              </div>

                              <div className="card-list">
                                <h4 className="card-title card-created_time">登録日</h4>
                                <p className="card-text">{moment(user.created_time).format('YYYY/MM/DD')}</p>
                              </div>

                              <div className="card-list">
                                <h4 className="card-title card-status">ステータス</h4>
                                <p className={`card-text ${user.status ? 'text-warning' : 'text-success'}`}>
                                  {' '}
                                  {user.status ? getTranslate(LANGUAGES.TEXT_INACTIVE) : getTranslate(LANGUAGES.TEXT_ACTIVE)}
                                </p>
                              </div>
                              <div className="card-list card-list-edit">
                                <h4 className="card-title card-title-icon">アクション</h4>
                                <div className="card-list-icon">
                                  <i style={{ cursor: 'pointer' }} className="fas fa-edit card-icon" onClick={() => this.handleChangeFormMode('update', user)}></i>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* <div className="card-toggle">
                            <button onClick={() => this.toggleCardVisibility(user.id)} className="btn btn-card-toggle">
                              {!hiddenCards[user.id] ? '情報を表示' : '情報を隠す'}
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <nav>
              <Pagination totalPage={this.state.totalPage} onClickPage={this.onSelectedPage} activeKey={this.state.selectedPage}></Pagination>
            </nav>
          </div>
        )}
        {/* ===== mode end begin ===== */}

        {/* ===== mode update and insert begin ===== */}
        {/* {console.log("check formmod:",this.state.formMode)} */}
        {['update', 'insert'].indexOf(this.state.formMode) > -1 && (
          <div style={{ display: 'flex' }} className="list-it w-100">
            <h1 style={{ color: 'black', fontSize: '26px' }}>アカウント登録</h1>
            <form className="update-form" style={{ maxWidth: 700, width: '90%' }}>
              {[
                { field: 'id', jp: '店舗ID', notViewIn: 'insert', readonly: true },
                { field: 'salon_id', jp: getTranslate(LANGUAGES.TEXT_SALON) },
                { field: 'name', jp: getTranslate(LANGUAGES.TEXT_NAME) },
                { field: 'username', jp: getTranslate(LANGUAGES.TEXT_USERNAME) },
                { field: 'phone', jp: getTranslate(LANGUAGES.TEXT_PHONE) },
                { field: 'password', jp: getTranslate(LANGUAGES.TEXT_PASSWORD) },
                { field: 'confirm_password', jp: getTranslate(LANGUAGES.TEXT_CONFIRM_PASSWORD) },
                { field: 'line_user_id', jp: getTranslate(LANGUAGES.TEXT_LINE_USER_ID) },
              ]
                .filter((x) => !x.notViewIn || (x.notViewIn && x.notViewIn != this.state.formMode))
                .map((d) => {
                  let isError = !!_.get(this.state, ['msg', d.field]);

                  return (
                    <div key={d.field} className="d-flex flex-column">
                      <div className="d-flex flex-row text-center">
                        <p style={{ color: 'black', fontSize: '16px',fontWeight:"bold", float: 'left', padding: 0, marginBottom: '.5rem' }}>{d.jp}</p>
                        {this.state.formMode === 'insert' && (
                          <span style={{ fontSize: '16px' }} className={`span-req ${d.field !== 'line_user_id' ? 'req' : ''}`}>{`${d.field !== 'line_user_id' ? '必須' : '任意'}`}</span>
                        )}
                        {this.state.formMode === 'update' && (
                          <span style={{ fontSize: '16px' }} className={`span-req ${d.field === 'line_user_id' || d.field === 'password' || d.field === 'confirm_password' ? '' : 'req'}`}>{`${
                            d.field === 'line_user_id' || d.field === 'password' || d.field === 'confirm_password' ? '任意' : '必須'
                          }`}</span>
                        )}
                      </div>

                      {d.field == 'salon_id' ? (
                        <select
                          style={{ width: '100%', height: 42 }}
                          className={`select-name ${isError ? 'error-input' : ''}`}
                          value={_.get(this.state, ['form', d.field], '')}
                          onChange={(e) => this.onChangeFormField(e, d.field)}
                        >
                          <option className="w-5">{getTranslate(LANGUAGES.TEXT_PLEASE_SELECT_SALON)}</option>
                          {lstSalon
                            .filter((x) => (this.state.formMode == 'insert' && !x.owner_id) || (this.state.formMode == 'update' && (!x.owner_id || x.id == this.state.selectedRow.salon_id)))
                            .map((s) => (
                              <option className="w-5 option-css" key={s.id} value={s.id}>
                                {s.name}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <input
                          style={{ maxHeight: '60px', width: '100%' }}
                          className={`${isError ? 'error-input' : ''}`}
                          type="text"
                          readOnly={d.readonly}
                          value={_.get(this.state, ['form', d.field], '') || ''}
                          onChange={(e) => this.onChangeFormField(e, d.field)}
                          data-field={d.field}
                        />
                      )}

                      {isError && (
                        <>
                          {Object.keys(this.state.msg[d.field]).map((fe) => (
                            <>
                              <p style={{ color: 'red', maxWidth: '500px' }}>{this.state.msg[d.field][fe]}</p>
                            </>
                          ))}
                        </>
                      )}

                      <br />
                    </div>
                  );
                })}
            </form>
            <div className="row-btn" style={{ maxWidth: 700, padding: 0}}>
              <button style={{ border: '1px solid', minWidth: 150, fontSize: 22, fontWeight: 'bold' }} type="button" className="btn btn-light" onClick={() => this.handleChangeFormMode('list', null)}>
                キャンセル
              </button>
              <button style={{ minWidth: 120, fontSize: 22,marginTop:0, fontWeight: 'bold' }} type="button" className={`btn ${this.state.formIsValid ? 'btn-success' : 'btn-cancel'}`} onClick={this.handleSaveForm}>
                OK
              </button>
            </div>
          </div>
        )}
        {/* ===== mode update and insert end ===== */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { salon_list } = state.salonReducer;
  const { staff_list, actionNotify } = state.staffReducer;

  return {
    salon_list,
    staff_list,
    actionNotify,
  };
};

export default connect(mapStateToProps, { salonList, staffList, staffCreate })(UserManagement);
