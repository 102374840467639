import React, { Component } from 'react';
import { Link } from 'react-router-dom'; // Import thư viện Link từ react-router-dom
import "../../../styles/components/tabBar.scss";

class TabBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: props.tab,
            description: props.description,
            isMobile: window.innerWidth <= 768, // assuming mobile width is 768px or less
            line_id: (new URLSearchParams(window.location.search)).get('line_user_id') ?? "",
            salon_uuid: (new URLSearchParams(window.location.search)).get('sid') ?? "",
        };
    }

    setActiveTab = (index) => {
        this.setState({ activeTab: index });
    };

    updateIsMobile = () => {
        this.setState({ isMobile: window.innerWidth <= 768 });
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }

    render() {
        const { activeTab, isMobile } = this.state;

        const menu = [
            {
                id: 1,
                title: "コースを選択",
                path: "/user/option?sid=" + this.state.salon_uuid + "&line_user_id=" + this.state.line_id
            },
            {
                id: 2,
                title: "日時を指定する",
                path: "/user/schedule?sid=" + this.state.salon_uuid + "&line_user_id=" + this.state.line_id
            },
            {
                id: 3,
                title: "お客様の情報を入力",
                path: "/user/inputcustomerInfo?sid=" + this.state.salon_uuid + "&line_user_id=" + this.state.line_id
            },
            {
                id: 4,
                title: "予約内容の確認",
                path: "/user/confirm?sid=" + this.state.salon_uuid + "&line_user_id=" + this.state.line_id
            },
            {
                id: 5,
                title: "予約完了",
                path: "/user/completion"
            },
        ];

       
        let filteredMenu = menu;
        if (isMobile) {
            if (activeTab <= 2) {
                filteredMenu = menu.slice(0, 2);
            } else if (activeTab >= 3) {
                filteredMenu = menu.slice(2);
            }
        }

        return (
            <div className='d-flex flex-column'>
                <div style={{ border: "1px solid #333" }} className="w-100 tab-container text-dark d-flex flex-row justify-content-between">
                    {filteredMenu.map((item, index) => (
                        <div to={item.path} key={item.id} className="tab-link text-dark w-25">
                            <div
                                style={{ userSelect: "none" }}
                                className={`tab-user ${activeTab > item.id ? 'active' : activeTab === item.id ? 'current-active' : ""} w-100 h-100 d-flex flex-row text-center align-items-center justify-content-around py-30 px-10`}
                                // onClick={() => this.setActiveTab(item.id)}
                            >
                                <h5 className='m-0 mr-30'>{item.id}.{item.title}</h5>
                                {(item.id < 5) && <i className="fa fa-chevron-right"></i>}
                            </div>
                        </div>
                    ))}
                </div>

                {this.props.description !== "" ? <p className='text-dark mt-10 mb-30 font-weight-medium tab-user-description'>{this.props.description}</p> : ""}
            </div>
        );
    }
}

export default TabBar;
