import React, { Component } from 'react';
import TabBar from '../../components/tabmenu/TabBar';
import NewInformationForm from './UserInformationForm';
import HeaderUser from '../../includes/HeaderUser';
import "../../../styles/components/inputInformation.scss"


export default class UserInformation extends Component {
    state = {
        user: 
        // {
        //     name: 'John Doe',
        //     phone: '123-456-7890',
        //     birthYear: 1985,
        //     gender: 0,
        //     purpose: 'Haircut'
        // }
        null
    };

    render() {
        const { user } = this.state;
        const isReturningUser = user?true:false
        return (
            <div className='container-fluid' style={{maxWidth:1200}}>
                <HeaderUser/>
                <TabBar tab={3} description="お客様の情報をご入力いただき、最下部の【予約内容を確認する】ボタンを押してください。
"/>
                <NewInformationForm isReturningUser={isReturningUser} user={user} />
            </div>
        );
    }
}
