import React, { Component } from 'react';

export default class Crreason extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: '',
      showError: false,
    };
  }

  handleOptionChange = (event) => {
    this.setState({
      selectedOption: event.target.value,
      showError: false,
    });
  };

  handleSubmit = (event) => {
    if (!this.state.selectedOption) {
      event.preventDefault();
      this.setState({ showError: true });
    }
  };

  render() {
    return (
      <div className="list-it">
        <div className="contain-main d-flex flex-column align-items-center">
          <h1 style={{ color: 'black', fontSize: '16px' }}>ご予約のキャンセル</h1>

          <div className="cf-main">
            <h1 style={{ color: 'black', fontSize: '14px', textAlign: 'center' }}>キャンセル理由を聞かせてください</h1>
            <input
              type="radio"
              id="option1"
              name="option"
              value="option1"
              checked={this.state.selectedOption === 'option1'}
              onChange={this.handleOptionChange}
            />
            <label htmlFor="option1">ご予定が中止</label>
            <br />
            <input
              type="radio"
              id="option2"
              name="option"
              value="option2"
              checked={this.state.selectedOption === 'option2'}
              onChange={this.handleOptionChange}
            />
            <label htmlFor="option2">お店を変更</label>
            <br />
            <input
              type="radio"
              id="option3"
              name="option"
              value="option3"
              checked={this.state.selectedOption === 'option3'}
              onChange={this.handleOptionChange}
            />
            <label htmlFor="option3">その他</label>
            
            {this.state.showError && (
              <p style={{ color: 'red', fontSize: '14px' }}>キャンセル理由を選択してください。</p>
            )}
          </div>
          
          <a href="/user/crconfirm" onClick={this.handleSubmit}>
            <button type="submit" className="btn-cf btn-success">
              確認画面へ
            </button>
          </a>
          <div className="row-btn row-cf">
            <a href="/user/crdetails">
              <button type="submit" className="btn-cancel">
                戻る
              </button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
