import React from 'react';
import thunk from 'redux-thunk';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import reducers from './reducers';

import MainIndex from './views';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = composeEnhancers(applyMiddleware(thunk))(createStore)(reducers);

export default function App() {
  return (
    <>
      <Provider store={store}>
        <Router>
          <MainIndex />
        </Router>
      </Provider>
    </>
  );
}
