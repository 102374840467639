import React, { Component } from 'react';

export default class ShiftTable extends Component {
    render() {
        const { data, handleInputChange, id, startIndex } = this.props;
        // const startIndex = id === "second-table" ? 11 : 0;
        return (
            <div className='container-fluid w-100 d-flex justify-content-center text-center mt-20'>
                <table className="table table-bordered table-case table-shift-pc">
                    <thead className='head-table'>
                        <tr>
                            <th>時間帯</th>
                            <th>枠</th>
                        </tr>
                    </thead>
                    <tbody className='text-dark font-weight-bold'>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.time}</td>
                                <td className='p-0'>
                                    <input
                                        className='input-slot text-center'
                                        type="number"
                                        value={item.people}
                                        onChange={(event) => handleInputChange(startIndex+index, event)}
                                        style={{ fontWeight: 700, width: '100%', padding: "0.75rem 0" }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}
