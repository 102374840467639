/* eslint-disable camelcase */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import _ from 'lodash';
import querystring from 'querystring';
import { API } from './API';

// export const getUserInfo = () => {
//   return API.get(`${process.env.REACT_APP_API_URL}/user/get`);
// };

export const login = (params) => {
  console.log("params: ", params);
  const result = API.post(
    `${process.env.REACT_APP_API_URL}/login/auth`,
    params
  );

  return result;
};

export const getUserInfo = (params) => {
  console.log("getUserInfo params: ", params);
  const result = API.get(
    `${process.env.REACT_APP_API_URL}/login/getUserInfo`,
    params
  );
  return result;
};



export const refreshToken = (id) => {
  const result = API.get(
    `${process.env.REACT_APP_API_URL}/login/refreshToken`,
    {}
  );

  return result;
};

export const createGuessUser = (params) => {
  const result = API.post(
    `${process.env.REACT_APP_API_URL}/guess/user/create`,
    params
  );
  return result;
};

export const userService = {
  login,
  getUserInfo,
  refreshToken,
  createGuessUser,
};
