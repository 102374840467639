import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { TYPES } from '../../../actions/types';
import { cleanGuessChoice, getUserInfo } from '../../../actions';
import '../../../styles/components/storeMenu.scss';

class MenuSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: {},
      actionNotify: {
        time: +new Date(),
      },
      menu: [
        {
          id: 1,
          name: 'パスワード変更',
          link: '/change-password',
        },
        {
          id: 2,
          name: '店舗情報設定',
          link: '/storeinfor-setting',
        },
      ],
    };
  }

  componentDidMount() {
    // this.props.cleanGuessChoice();
    this.props.getUserInfo();
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { type } = props.actionNotify;
    // if (!_.isEmpty(props.actionNotify) && props.actionNotify.time > this.state.actionNotify.time) {
    //   switch (type) {
    //     case TYPES.ACTION_USER_GET_USER_INFO_SUCCESS:
    //       const salon_uuid = _.get(props, 'userInfo.salon_code', '');
    //       const { menu } = this.state;
    //       menu[0].link = `/user/option?sid=${salon_uuid}`;
    //       break;
    //   }
    // }
  }

  renderMenu = () => {
    return this.state.menu.map((m, index) => {
      return (
        <button
          key={index}
          className="btn btn-success mb-20 mx-auto font-weight-medium p-0 d-flex justify-content-center align-items-center"
          style={{ width: '100%', height: '10vh', minWidth: 200, minHeight: 80, maxHeight: 90, maxWidth: 900 }}
        >
          <Link className="d-flex text-white w-100 h-100 justify-content-center align-items-center" style={{ fontSize: '22px', fontWeight: 'bold' }} to={m.link}>
            {m.name}
          </Link>
        </button>
      );
    });
  };

  render() {
    return (
      <div className="container-fluid px-lg-50 store-page d-flex flex-column">
        <h2 className="title-store-menu text-center text-dark mb-20" style={{ fontSize: '26px', fontWeight: 'bold' }}>
          設定
        </h2>

        <div className="menu store-menu text-center d-flex flex-column justify-content-center">{this.renderMenu()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { userReducer } = state;
  return {
    actionNotify: userReducer.actionNotify,
    userInfo: userReducer.userInfo,
  };
}

export default connect(mapStateToProps, {
  cleanGuessChoice,
  getUserInfo,
})(MenuSetting);
