import React, { Component } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import './../../../styles/components/linkline.scss';
import TabBar from '../../components/tabmenu/TabBar';

import { withRouter } from 'react-router-dom';
import { notify, getTranslate, LANGUAGES } from '../../../utils';
import { connect } from 'react-redux';
import { TYPES } from '../../../actions';
import _ from 'lodash';
import Func from '../../../utils/Func';

class BookingInvalidLineUserId extends Component {
  render() {
    return (
      <div className="container-fluid" style={{ maxWidth: 1200 }} id="user-completion">
        <h1 className=" d-flex  justify-content-center" style={{ fontSize: '22px', color: 'black' }}>
          予約を実行できません。
        </h1>
        <div className="w-100 card-completion text-dark mt-70">
          <div className="card-completion-main w-100 d-flex flex-column text-center justify-content-center pt-40 pb-70" style={{ border: '2px solid #333' }}>
            <div>
              <i style={{ fontSize: '55px' }} class="fa-regular fa-circle-xmark"></i>
            </div>
            <div className="card-completion-content d-flex flex-column justify-content-center">
              <h4 className="my-10" style={{ fontSize: '20px' }}></h4>
              <p className="m-0 PC-version">
                無効なURLを検出しました。
                <br />
                ブラウザを閉じてやり直してください。
              </p>
              <p className="m-0 mobile-version">
                無効なURLを検出しました。
                <br />
                ブラウザを閉じてやり直してください。
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, {})(BookingInvalidLineUserId));
