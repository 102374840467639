import React, { Component } from 'react';
import './../../../styles/components/viewseat.scss';
import './../../../styles/components/confirmcancel.scss';
export default class Viewseat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
     
      datas: [
        {
          salonName: 'Beauon store A',
          visitDateTime: 'MM月DD日（Day）◯◯:◯◯',
          totalDuration: '◯時間',
          options: 'オプションA・・・・・',
          totalPrice: 'X,XXX円 ',
          customerName: '田口純平',
          phoneNumber: '090xxxxxxxx',
          birthYear: '1999',
          sex:"Men",
          purpose:"体がだるい為",      
    
        },
       
        // Add more data items as needed
      ],
    };
  }
  
  
  
  render() {
    return(
      <div>
      <div className=" list-it">
      <div className=" contain-main d-flex flex-column  align-items-center">
        <h1 style={{ color: 'black', fontSize: '22px' }}>ご予約内容の詳細</h1>
        
        <div className="content-main2" style={{maxWidth:700,width:"100%"}}>
        {this.state.datas.map((data, index) => (
        <div key={index}>
          <div className="gray-bg">
            <h1 style={{ color: 'black', fontSize: '16px' }}>ご予約内容</h1>
          </div>
          <h1 style={{ color: 'black', fontSize: '16px' }}>サロン名 </h1>
          <p  style={{ color: 'black', fontSize: '16px' }}>{data.salonName}</p>
          <h1 style={{ color: 'black', fontSize: '16px' }}>来店日時 </h1>
          <p  style={{ color: 'black', fontSize: '16px' }}>{data.visitDateTime}</p>
          <h1 style={{ color: 'black', fontSize: '16px' }}>所要時間合計（目安）</h1>
          <p  style={{ color: 'black', fontSize: '16px' }}>{data.totalDuration}</p>
          <h1 style={{ color: 'black', fontSize: '16px' }}>オプション </h1>
          <p  style={{ color: 'black', fontSize: '16px' }}>{data.options}</p>
          <h1 style={{ color: 'black', fontSize: '16px' }}>合計金額 </h1>
          <p  style={{ color: 'black', fontSize: '16px' }}>{data.totalPrice}</p>
          <div className="gray-bg">
            <h1 style={{ color: 'black', fontSize: '16px' }}>お客様情報</h1>
          </div>
          <h1 style={{ color: 'black', fontSize: '16px' }}>ご予約者氏名 </h1>
          <p  style={{ color: 'black', fontSize: '16px' }}>{data.customerName}</p>
          <h1 style={{ color: 'black', fontSize: '16px' }}>電話番号 </h1>
          <p  style={{ color: 'black', fontSize: '16px' }}>{data.phoneNumber}</p>
          <h1 style={{ color: 'black', fontSize: '16px' }}>生年 </h1>
          <p  style={{ color: 'black', fontSize: '16px' }}>{data.birthYear}</p>
          <h1 style={{ color: 'black', fontSize: '16px' }}>性別</h1>
          <p  style={{ color: 'black', fontSize: '16px' }}>{data.sex}</p>
          <h1 style={{ color: 'black', fontSize: '16px' }}>来店の目的</h1>
          <p  style={{ color: 'black', fontSize: '16px' }}>{data.purpose}</p>
         
        </div>
      ))}
          
        </div>
       <p  style={{ color: 'black', fontSize: '14px' }}>ご予約内容のキャンセルは、予約日時（来店日時）の24時間前まで受付可能です。</p>
       <a href="/user/crreason"><button className="btn-cf btn-success" >予約キャンセル</button></a>
      </div>
      </div>
      
      </div>
    );
  }
}
