import React, { Component } from 'react';
import InputForm from '../atoms/inputForm';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import _ from 'lodash';
import { TYPES, staffChangePassword, getUserInfo } from '../../../actions';

import { LANGUAGES, getTranslate, checkValidate, notify } from '../../../utils';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,

      userInfo: props.userInfo,

      formMode: 'update',
      form: {
        password: '',
        new_password: '',
        confirm_password: '',
      },
      formIsValid: false,
      msg: {},

      actionNotify: {
        time: +new Date(),
      },
    };

    this.validates = [
      { field: 'password', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_PASSWORD) },
      { field: 'new_password', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_NEW_PASSWORD) },
      { field: 'new_password', type: 'min_length', value: 8, msg: getTranslate(LANGUAGES.TEXT_PASSWORD_HAVE_LESS_THAN_8_CHARACTERS) },
      {
        field: 'new_password',
        type: 'must_has_number_and_character',
        value: 10,
        msg: getTranslate(LANGUAGES.TEXT_PASSWORD_MUST_BE_AT_LEAST_10_CHARACTERS_LONG_AND_INCLUDE_AT_LEAST_ONE_LETTER__ONE_NUMBER__ONE_SPECIAL_CHARACTER),
      },
      { field: 'confirm_password', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_CONFIRM_PASSWORD) },
      { field: 'confirm_password', type: 'match', value: true, msg: getTranslate(LANGUAGES.TEXT_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH) },
    ];
    this.regexCheckPassword = /^(?=.*[A-Za-z@#$%^&*()_\-+])(?=.*\d)[A-Za-z\d@#$%^&*()_\-+]{8,}$/;
    this.regexCheckPasswordAdmin = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+])[A-Za-z\d!@#$%^&*()_\-+]{10,}$/;
  }

  componentDidMount() {
    this.props.getUserInfo();
  }

  UNSAFE_componentWillReceiveProps(props) {
    let newState = {
      ..._.pick(props, ['userInfo']),
    };

    ['userInfo'].forEach((field) => {
      if (JSON.stringify(newState[field]) == this.state[field]) {
        delete newState[field];
      }
    });

    if (!_.isEmpty(newState)) {
      let isAdmin = _.get(newState, 'userInfo.role') == 0;
      let validate = this.validates.find((x) => x.field == 'new_password' && x.type == 'must_has_number_and_character');
      let validate2 = this.validates.find((x) => x.field == 'new_password' && x.type == 'min_length');
      if (isAdmin) {
        validate.msg = getTranslate(LANGUAGES.TEXT_PASSWORD_MUST_BE_AT_LEAST_10_CHARACTERS_LONG_AND_INCLUDE_AT_LEAST_ONE_LETTER__ONE_NUMBER__ONE_SPECIAL_CHARACTER);
        validate2.value = 10;
        validate2.msg = getTranslate(LANGUAGES.TEXT_PASSWORD_HAVE_LESS_THAN_10_CHARACTERS);
      } else {
        validate.msg = getTranslate(LANGUAGES.TEXT_PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS_LONG_AND_INCLUDE_AT_LEAST_ONE_LETTER__ONE_NUMBER__OR_ONE_SPECIAL_CHARACTER);
        validate2.value = 8;
        validate2.msg = getTranslate(LANGUAGES.TEXT_PASSWORD_HAVE_LESS_THAN_8_CHARACTERS);
      }
      this.setState(newState, () => {});
    }

    if (!_.isEmpty(props.actionNotify) && props.actionNotify.time > this.state.actionNotify.time) {
      switch (props.actionNotify.type) {
        case TYPES.ACTION_STAFF_CHANGE_PASSWORD_SUCCESS:
          notify(getTranslate(LANGUAGES.TEXT_CHANGE_PASSWORD_SUCCESSFULLY), 'success');
          this.setState({
            form: {
              password: '',
              new_password: '',
              confirm_password: '',
            },
          });
          document.getElementById('txtPassword').setCustomValidity('');
          document.getElementById('txtNewPassword').setCustomValidity('');
          document.getElementById('txtConfirmPassword').setCustomValidity('');

          if (this.form) this.form.classList.remove('was-validated');

          break;

        case TYPES.ACTION_CALL_API_FAIL:
          let msgError = '';
          switch (props.actionNotify.error_code) {
            case 1002:
              msgError = getTranslate(LANGUAGES.WRONG_PASSWORD);
              this.setState({
                msg: {
                  ...this.state.msg,
                  password: msgError,
                },
              });
              notify(msgError, 'error');
              document.getElementById('txtPassword').setCustomValidity(msgError);

              break;

            default:
              break;
          }

        default:
          break;
      }
      this.setState({
        actionNotify: props.actionNotify,
      });
    }
  }

  onChangeFormField = (e, field) => {
    const value = e.target.value;
    let newForm = {
      ...this.state.form,
      [field]: value,
    };

    this.setState({
      form: newForm,
      msg: {},
    });

    document.getElementById('txtPassword').setCustomValidity('');
    document.getElementById('txtNewPassword').setCustomValidity('');
    document.getElementById('txtConfirmPassword').setCustomValidity('');

    if (this.form) this.form.classList.remove('was-validated');
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    this.form = form;

    let validates = this.validates.filter((x) => !x.mode || x.mode.indexOf(this.state.formMode) > -1);

    let msg = checkValidate(validates, this.state.form, true);

    let isAdmin = _.get(this.state, 'userInfo.role') == 0;
    if (!msg.new_password) {
      let regex = isAdmin ? this.regexCheckPasswordAdmin : this.regexCheckPassword;
      let msgError =
        !this.state.form.new_password || !regex.test(this.state.form.new_password) ? this.validates.find((x) => x.type == 'must_has_number_and_character' && x.field == 'new_password').msg : '';
      if (msgError) {
        msg.new_password = msgError;
      }
    }
    if (!msg.confirm_password) {
      let msgError =
        !this.state.form.confirm_password || this.state.form.new_password != this.state.form.confirm_password ? this.validates.find((x) => x.type == 'match' && x.field == 'confirm_password').msg : '';
      if (msgError) {
        msg.confirm_password = msgError;
      }
    }

    let formIsValid = _.isEmpty(msg);

    this.setState({
      msg,
      formIsValid,
    });

    document.getElementById('txtPassword').setCustomValidity(_.get(msg, 'password', ''));
    document.getElementById('txtNewPassword').setCustomValidity(_.get(msg, 'new_password', ''));
    document.getElementById('txtConfirmPassword').setCustomValidity(_.get(msg, 'confirm_password', ''));

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      console.log('form is invalid');
    } else {
      console.log('form is valid');
      this.props.staffChangePassword({
        username: _.get(this.state, 'userInfo.username'),
        password: this.state.form.password,
        new_password: this.state.form.new_password,
      });
    }

    form.classList.add('was-validated');
  };

  render() {
    const { error } = this.state;

    let isAdmin = _.get(this.state, 'userInfo.role') == 0;

    return (
      <div className="text-center">
        <h1 style={{ color: 'black', fontSize: '26px', fontWeight: 'bold' }}>パスワード変更</h1>
        <h2 style={{ color: 'black', fontSize: '16px', marginBottom: '20px', fontWeight: 'bold' }}>
          {isAdmin ? 'パスワードは10文字以上で半角英数字と記号の組み合わせを入力してください。' : 'パスワードは半角英数字8文字以上入力してください。'}
        </h2>

        <div className="d-flex justify-content-center">
          <form noValidate autoComplete="off" className={'needs-validation'} onSubmit={this.handleSubmit} style={{ width: '90%', maxWidth: 500 }}>
            <div className="mx-auto" style={{ width: '100%' }}>
              <p style={{ float: 'left', color: 'black', fontSize: '16px' }}>現在のパスワード</p>
              <InputForm
                style={{ width: '100%' }}
                type={'password'}
                required="true"
                id="txtPassword"
                msg={_.get(this.state, 'msg.password', '')}
                value={this.state.form.password}
                onChange={(e) => this.onChangeFormField(e, 'password')}
              />

              {/* <Link lassName="text-center" style={{ float: 'left', color: 'blue', fontSize: '16px', textDecoration: 'underline' }} to="/forget-password">
                パスワードをお忘れですか？
              </Link> */}
              
              <p style={{ float: 'left', color: 'black', fontSize: '16px' }}>新しいパスワード</p>
              <InputForm
                style={{ width: '100%' }}
                type={'password'}
                required="true"
                id="txtNewPassword"
                msg={_.get(this.state, 'msg.new_password', '')}
                value={this.state.form.new_password}
                onChange={(e) => this.onChangeFormField(e, 'new_password')}
              />
              <p style={{ float: 'left', color: 'black', fontSize: '16px' }}>確認パスワード</p>
              <InputForm
                style={{ width: '100%' }}
                type={'password'}
                required="true"
                id="txtConfirmPassword"
                msg={_.get(this.state, 'msg.confirm_password', '')}
                value={this.state.form.confirm_password}
                onChange={(e) => this.onChangeFormField(e, 'confirm_password')}
              />

              {error && <div style={{ color: 'red' }}>{error}</div>}

              <button style={{ width: '100%', fontWeight: 'bold',fontSize:'22px' }} type="submit" className="btn btn-success btn-block btn-round">
                変更する
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userInfo } = state.userReducer;
  const { actionNotify } = state.staffReducer;

  return {
    userInfo,
    actionNotify,
  };
};

export default connect(mapStateToProps, { staffChangePassword, getUserInfo })(ChangePassword);
