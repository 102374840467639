import React, { Component } from 'react';
import { BsCheckCircle } from 'react-icons/bs';

import TabBar from '../../components/tabmenu/TabBar';
import HeaderUser from '../../includes/HeaderUser';
import { withRouter } from 'react-router-dom';
import { notify } from '../../../utils';
import { getGuessChoice } from '../../../actions';
import { connect } from 'react-redux';
import { TYPES } from '../../../actions';
import _ from 'lodash';
import Func from '../../../utils/Func';

class UserCompletion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingState: '',
      isBackAvailable: false,
      isGetGuessChoice: false,
    };
  }
  componentDidMount() {
    this.props.getGuessChoice();
  }

  componentWillUnmount() {
    if (this.state.bookingState === 'success') {
      console.log('this.state.bookingState: ', this.state.bookingState);
      this.props.history.push('/user/completion');
      notify('異常な操作を検出しました。\nブラウザを閉じてやり直してください。', 'error');
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    console.log('UserCompletion UNSAFE_componentWillReceiveProps props: ', props);
    const type = props.actionNotify.type;
    switch (type) {
      case TYPES.ACTION_GET_GUEST_CHOICE:
        this.setState({ bookingState: _.get(props, 'actionNotify.guessChoicing.bookingState', '') });
        window.localStorage.removeItem('guessChoicing');
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div className="container-fluid" style={{ maxWidth: 1200 }} id="user-completion">
        <HeaderUser />
        <TabBar tab={5} description="" />
        <div className="w-100 card-completion text-dark mt-70">
          <div className="card-completion-main w-100 d-flex flex-column text-center justify-content-center pt-40 pb-70" style={{ border: '2px solid #333' }}>
            <div>
              <BsCheckCircle className="check-icon" fontSize={36} />
            </div>
            <div className="card-completion-content d-flex flex-column justify-content-center">
              <h4 className="my-30" style={{ fontSize: '20px' }}>
                予約が完了しました。
                <br />
                ご来店を心よりお待ちしております。
              </h4>
              <p className="m-0 ">
                ご利用いただき誠にありがとうございます。
                <br />
                予約完了メッセージをお送りしましたので、
                <br />
                内容をご確認ください。
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { staffReducer } = state;
  return {
    actionNotify: staffReducer.actionNotify,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getGuessChoice,
  })(UserCompletion)
);
