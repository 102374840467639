import React, { Component, Fragment } from 'react';

const textPrev = '前へ';
const textNext = '次へ';

class Pagination extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      forgotForm: false,
      headPages: [1, 2, 3],
      lastPages: [4, 5, 6],
      activeKey: this.props.activeKey,
    };
  }

  clickPage = (page) => {
    if (page < 1 || page > this.props.totalPage) {
      return;
    }

    this.setState({ activeKey: page });
    this.props.onClickPage(page);
  };
  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.activeKey != props.activeKey) {
      this.setState({ activeKey: props.activeKey });
    }
  }
  renderAdvancedPage = (activeKey) => {
    const totalPage = Number(this.props.totalPage);
    let headPages = [];
    let lastPages = [];
    // 1 2 3 4 5 6 7 8 9
    if (activeKey === 1) {
      headPages = [1, 2, 3];
      if (totalPage >= 9) {
        lastPages = [7, 8, 9];
      } else {
        for (let i = totalPage; i > headPages[2]; i--) {
          lastPages.push(i);
        }
        lastPages.sort(function (a, b) {
          return a - b;
        });
      }
    } else if (totalPage - 2 <= activeKey && activeKey <= totalPage) {
      for (let i = totalPage - 3; i >= 1; i--) {
        if (headPages.length >= 3) break;
        headPages.push(i);
      }
      headPages.sort(function (a, b) {
        return a - b;
      });
      for (let i = totalPage; i > Number(headPages[2]); i--) {
        if (lastPages.length >= 3) break;
        lastPages.push(i);
      }
      lastPages.sort(function (a, b) {
        return a - b;
      });
    } else {
      headPages = [activeKey - 1, activeKey, activeKey + 1];
      if (activeKey + 7 <= totalPage) {
        lastPages = [activeKey + 4, activeKey + 5, activeKey + 6];
      } else {
        for (let i = totalPage; i > Number(headPages[2]); i--) {
          if (lastPages.length >= 3) break;
          lastPages.push(i);
        }
        lastPages.sort(function (a, b) {
          return a - b;
        });
      }
    }

    return (
      <Fragment>
        <ul className={`pagination mb-0 d-flex justify-content-center${this.props.modifyClass || ''}`}>
          <li className={`page-item page-arrow page-arrow-first${this.state.activeKey == 1 ? ' disabled' : ''}`} onClick={() => this.clickPage(1)}>
            <a className="page-link" href="#" aria-label={textPrev}>
              <i className={'fa fa-angle-double-left fs-12'} aria-hidden="true" />
              <span className="sr-only">{textPrev}</span>
            </a>
          </li>
          <li className={`page-item page-arrow page-arrow-prev${this.state.activeKey == 1 ? ' disabled' : ''}`} onClick={() => this.clickPage(this.state.activeKey - 1)}>
            <a className={`page-link`} href="#" aria-label={textPrev}>
              <i className={'fa fa-angle-left fs-12'} aria-hidden="true" />
              <span className="sr-only">{textPrev}</span>
            </a>
          </li>
          {headPages.map((page, Idx) => (
            <li key={Idx} className="page-item page-number" onClick={() => this.clickPage(page)}>
              <a key={Idx} className={`page-link ${page === this.state.activeKey ? 'active-page' : ''}`}>
                {page}
              </a>
            </li>
          ))}
          {(lastPages.length > 0 && lastPages[0] - headPages[2]) > 1 ? (
            <li className="page-item page-number">
              <a className="page-link page-dot">...</a>
            </li>
          ) : null}
          {lastPages.map((page, Idx) => (
            <li key={Idx} className="page-item page-number" onClick={(e) => this.clickPage(page)}>
              <a className={`page-link ${page === this.state.activeKey ? 'active-page' : ''}`}>{page}</a>
            </li>
          ))}
          <li className={`page-item page-arrow page-arrow-next${this.state.activeKey === this.props.totalPage ? ' disabled' : ''}`} onClick={() => this.clickPage(this.state.activeKey + 1)}>
            <a className="page-link" href="#" aria-label={textNext}>
              <i className={'fa fa-angle-right fs-12'} aria-hidden="true" />
              <span className="sr-only">{textNext}</span>
            </a>
          </li>
          <li className={`page-item page-arrow page-arrow-last${this.state.activeKey === this.props.totalPage ? ' disabled' : ''}`} onClick={() => this.clickPage(this.props.totalPage)}>
            <a className="page-link" href="#" aria-label={textPrev}>
              <i className={'fa fa-angle-double-right fs-12'} aria-hidden="true" />
              <span className="sr-only">{textNext}</span>
            </a>
          </li>
        </ul>
      </Fragment>
    );
  };

  renderNormalPage = () => {
    const smallPages = [];
    for (let i = 1; i <= this.props.totalPage; i++) {
      smallPages.push(i);
    }

    return (
      <Fragment>
        <ul className="pagination mb-0 d-flex justify-content-center">
          <li className={`page-item page-arrow page-arrow-prev${this.state.activeKey == 1 ? ' disabled' : ''}`} onClick={() => this.clickPage(1)}>
            <a className="page-link" href="#" aria-label={textPrev}>
              <span aria-hidden="true">&laquo;</span>
              <span className="sr-only">{textPrev}</span>
            </a>
          </li>
          <li className={`page-item page-arrow page-arrow-prev${this.state.activeKey == 1 ? ' disabled' : ''}`} onClick={() => this.clickPage(this.state.activeKey - 1)}>
            <a className="page-link" href="#" aria-label={textPrev}>
              <i className={'fa fa-angle-left fs-12'} />
              <span className="sr-only">{textPrev}</span>
            </a>
          </li>
          {smallPages.map((page) => (
            <li className="page-item page-number" key={page} onClick={(e) => this.clickPage(page)}>
              <a className={`page-link ${page === this.state.activeKey ? 'active-page' : ''}`}>{page}</a>
            </li>
          ))}
          <li className={`page-item page-number${this.state.activeKey === this.props.totalPage ? ' disabled' : ''}`} onClick={() => this.clickPage(this.state.activeKey + 1)}>
            <a className="page-link" href="#" aria-label={textNext}>
              <i className={'fa fa-angle-right fs-12'} />
              <span className="sr-only">{textNext}</span>
            </a>
          </li>
          <li className={`page-item page-number${this.state.activeKey === this.props.totalPage ? ' disabled' : ''}`} onClick={() => this.clickPage(this.props.totalPage)}>
            <a className="page-link" href="#" aria-label={textPrev}>
              <span aria-hidden="true">&raquo;</span>
              <span className="sr-only">{textNext}</span>
            </a>
          </li>
        </ul>
      </Fragment>
    );
  };

  render() {
    return (
      <div className={`px-30 py-20${this.props.className || ''}`} id="my-pagination" show={(Number(this.props.totalPage) > 0).toString()}>
        {Number(this.props.totalPage) > 0 ? (
          <nav aria-label={'Pagination'}>{this.props.totalPage > 6 ? this.renderAdvancedPage(Number(this.state.activeKey)) : this.renderNormalPage(Number(this.state.activeKey))}</nav>
        ) : null}
      </div>
    );
  }
}

export default Pagination;
