import React, { Component } from 'react'
import './../../../styles/components/confirmcancel.scss';
export default class Crcompletion extends Component {
  render() {
    return (
      <div className=" list-it container-fluid" style={{maxWidth:1200}}>

        <h1 class="title-sp" style={{ color: 'black', fontSize: '26px' }}>ご予約のキャンセルが完了</h1>

        <div className="box-comple d-flex flex-column align-items-center w-100" >
          <img className='img-done' src="/static/images/tick.png"></img>
          <h1 style={{ color: 'black', fontSize: '20px', fontWeight: "bold" }}>予約キャンセルが完了しました。</h1>
          <p class="PC-version" style={{ color: 'black', fontSize: '16px' }}>予約キャンセル完了LINEメッセージをお送りましたので、</p>
          <p class="PC-version" style={{ color: 'black', fontSize: '16px' }}>内容をご確認ください。</p>
          <p class="mobile-version text-center " style={{ color: 'black', fontSize: '16px' }}>予約キャンセル完了LINEメッセージをお送りましたので、内容をご確認ください。</p>
        </div>
      </div>
    )
  }
}
