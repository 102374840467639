import React, { Component } from 'react';
import InputForm from '../atoms/inputForm';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TYPES, staffResetPassword } from '../../../actions';

import { LANGUAGES, getTranslate, checkValidate, notify } from '../../../utils';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,

      userInfo: {},

      formMode: 'update',
      form: {
        password: '',
        confirm_password: '',
      },
      formIsValid: false,
      msg: {},

      actionNotify: {
        time: +new Date(),
      },
    };

    this.validates = [
      { field: 'password', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_PASSWORD) },

      { field: 'password', type: 'min_length', value: 8, msg: getTranslate(LANGUAGES.TEXT_PASSWORD_HAVE_LESS_THAN_8_CHARACTERS) },
      {
        field: 'password',
        type: 'must_has_number_and_character',
        value: 8,
        msg: getTranslate(LANGUAGES.TEXT_PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS_LONG_AND_INCLUDE_AT_LEAST_ONE_LETTER__ONE_NUMBER__OR_ONE_SPECIAL_CHARACTER),
      },
      { field: 'confirm_password', type: 'required', value: true, msg: getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_CONFIRM_PASSWORD) },
      { field: 'confirm_password', type: 'match', value: true, msg: getTranslate(LANGUAGES.TEXT_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH) },
    ];
    this.regexCheckPassword = /^(?=.*[A-Za-z@#$%^&*()_\-+])(?=.*\d)[A-Za-z\d@#$%^&*()_\-+]{8,}$/;
    this.regexCheckPasswordAdmin = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+])[A-Za-z\d!@#$%^&*()_\-+]{10,}$/;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!_.isEmpty(props.actionNotify) && props.actionNotify.time > this.state.actionNotify.time) {
      switch (props.actionNotify.type) {
        case TYPES.ACTION_STAFF_RESET_PASSWORD_SUCCESS:
          notify(getTranslate(LANGUAGES.TEXT_PASSWORD_HAS_BEEN_RESET_SUCCESSFULLY), 'success');
          this.setState({
            form: {
              password: '',
              confirm_password: '',
            },
          });
          document.getElementById('txtPassword').setCustomValidity('');
          document.getElementById('txtConfirmPassword').setCustomValidity('');

          if (this.form) this.form.classList.remove('was-validated');

          setTimeout(() => {
            window.location.href = '/login';
          }, 1000);

          break;

        case TYPES.ACTION_CALL_API_FAIL:
          let msgError = '';
          switch (props.actionNotify.error_code) {
            case 1002:
              msgError = getTranslate(LANGUAGES.WRONG_PASSWORD);
              this.setState({
                msg: {
                  ...this.state.msg,
                  password: msgError,
                },
              });
              notify(msgError, 'error');
              document.getElementById('txtPassword').setCustomValidity(msgError);

              break;
            case 1001:
              msgError = getTranslate(LANGUAGES.TOKEN_IS_NOT_VALID_OR_EXPIRED);
              this.setState({
                msg: {
                  ...this.state.msg,
                  password: msgError,
                },
              });
              notify(msgError, 'error');
              document.getElementById('txtPassword').setCustomValidity(msgError);
              setTimeout(() => {
                window.location.href = '/login';
              }, 2000);

              break;
            default:
              break;
          }

        default:
          break;
      }
      this.setState({
        actionNotify: props.actionNotify,
      });
    }
  }

  onChangeFormField = (e, field) => {
    const value = e.target.value;
    let newForm = {
      ...this.state.form,
      [field]: value,
    };

    this.setState({
      form: newForm,
      msg: {},
    });

    document.getElementById('txtPassword').setCustomValidity('');
    document.getElementById('txtConfirmPassword').setCustomValidity('');

    if (this.form) this.form.classList.remove('was-validated');
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    this.form = form;

    let validates = this.validates.filter((x) => !x.mode || x.mode.indexOf(this.state.formMode) > -1);

    let msg = checkValidate(validates, this.state.form, true);

    let isAdmin = false;
    if (!msg.password) {
      let regex = isAdmin ? this.regexCheckPasswordAdmin : this.regexCheckPassword;
      let msgError = !this.state.form.password || !regex.test(this.state.form.password) ? this.validates.find((x) => x.type == 'must_has_number_and_character' && x.field == 'password').msg : '';
      if (msgError) {
        msg.password = msgError;
      }
    }
    if (!msg.confirm_password) {
      let msgError =
        !this.state.form.confirm_password || this.state.form.password != this.state.form.confirm_password ? this.validates.find((x) => x.type == 'match' && x.field == 'confirm_password').msg : '';
      if (msgError) {
        msg.confirm_password = msgError;
      }
    }

    let formIsValid = _.isEmpty(msg);

    this.setState({
      msg,
      formIsValid,
    });

    document.getElementById('txtPassword').setCustomValidity(_.get(msg, 'password', ''));
    document.getElementById('txtConfirmPassword').setCustomValidity(_.get(msg, 'confirm_password', ''));

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      console.log('form is invalid');
    } else {
      console.log('form is valid');
      this.props.staffResetPassword({
        token: new URLSearchParams(window.location.search).get('token'),
        new_password: this.state.form.password,
      });
    }

    form.classList.add('was-validated');
  };
  render() {
    return (
      <div className="text-center ">
        <h1 style={{ color: 'black', fontSize: '26px',fontWeight:"bold" }}>パスワード再設定</h1>
        <p style={{ color: 'black', fontSize: '16px',fontWeight:"bold" }}>パスワードは半角英数字8文字以上入力してください。</p>

        <div className="d-flex justify-content-center">
          <form style={{maxWidth:500,width:"100%",margin:"0.5rem"}} noValidate autoComplete="off" className={'needs-validation'} onSubmit={this.handleSubmit}>
            <label style={{ float: 'left', color: 'black', fontSize: '16px' }}>新しいパスワード</label>
            <InputForm
              className="mx-auto"
              style={{ width: '100%',maxWidth:500 }}
              type={'password'}
              id="txtPassword"
              required="true"
              msg={_.get(this.state, 'msg.password', '')}
              value={this.state.form.password}
              onChange={(e) => this.onChangeFormField(e, 'password')}
            />

            <label style={{ float: 'left', color: 'black', fontSize: '16px' }}>確認パスワード</label>
            <InputForm
              className="mx-auto"
              style={{ width: '100%',maxWidth:500 }}
              type={'password'}
              id="txtConfirmPassword"
              required="true"
              msg={_.get(this.state, 'msg.confirm_password', '')}
              value={this.state.form.confirm_password}
              onChange={(e) => this.onChangeFormField(e, 'confirm_password')}
            />

            <button style={{ width: '100%', marginTop: '20px',fontSize:'22px',fontWeight:'bold' }} type="submit" className="btn btn-success btn-block btn-round">
              再設定する
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userInfo } = state.userReducer;
  const { actionNotify } = state.staffReducer;

  return {
    userInfo,
    actionNotify,
  };
};

export default connect(mapStateToProps, { staffResetPassword })(ResetPassword);
