import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputForm from '../atoms/inputForm';
import { login } from '../../../actions';
import _ from 'lodash';
import { TYPES } from '../../../actions/types';
import { Link } from 'react-router-dom';
import { LANGUAGES, getTranslate, notify } from '../../../utils';

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        username: '',
        password: '',
        keep_login: false,
      },
      msg: {
        username: '',
        password: '',
      },
      actionNotify: {
        time: +new Date(),
      },
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    console.log('UNSAFE_componentWillReceiveProps', props);
    if (!_.isEmpty(props.actionNotify) && props.actionNotify.time > this.state.actionNotify.time) {
      console.log('props.actionNotify.type: ', props.actionNotify.type);
      switch (props.actionNotify.type) {
        case TYPES.ACTION_LOGIN_SUCCESS:
          console.log('ACTION_LOGIN_SUCCESS');
          const is_admin = props.userInfo.is_admin;
          // localStorage.setItem('userInfo', JSON.stringify(props.userInfo));
          is_admin ? (window.location.href = '/admin/menu') : (window.location.href = '/store/menu');
          break;
        case TYPES.ACTION_LOGIN_FAIL:
          switch (props.actionNotify.error_code) {
            case 1000:
              this.setState({
                msg: {
                  ...this.state.msg,
                  username: 'ユーザーが見つかりません',
                  password: '',
                },
              });
              notify('ユーザーが見つかりません', 'error');
              break;
            case 1001:
              this.setState({
                msg: {
                  ...this.state.msg,
                  username: 'ユーザーがアクティブではありません',
                  password: '',
                },
              });
              notify('ユーザーがアクティブではありません', 'error');

              break;

            case 1002:
              this.setState({
                msg: {
                  ...this.state.msg,
                  username: '',
                  password: 'パスワードが間違っています',
                },
              });
              notify('パスワードが間違っています', 'error');

              break;
            case 1003:
              this.setState({
                msg: {
                  ...this.state.msg,
                  username: getTranslate(LANGUAGES.TEXT_ACCOUNT_IS_NOT_ACTIVE),
                  password: '',
                },
              });
              notify(getTranslate(LANGUAGES.TEXT_ACCOUNT_IS_NOT_ACTIVE), 'error');

              break;
          }
          break;

        default:
          break;
      }

      this.setState({ actionNotify: props.actionNotify });
    }
  }

  onHandleChangeInput = (e, field) => {
    this.setState({
      form: {
        ...this.state.form,
        [field]: field != 'keep_login' ? e.target.value.trim() : !this.state.form.keep_login,
      },
      msg: {
        username: '',
        password: '',
      },
    });
  };

  onHandleSubmitLogin = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const username = form.txtUserName.value;
      const password = form.txtPassword.value;
      this.props.login({ username, password, keep_login: this.state.form.keep_login });
    }

    form.classList.add('was-validated');
  };

  render() {
    return (
      <div
        className="modal fade show"
        id="loginModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        style={{ display: 'block', height: 'calc(100% - 200px)', top: '50%', transform: 'translateY(-50%)' }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-title text-center mb-50">
                <h1 style={{ color: 'black', fontSize: '26px', fontWeight: 'bold' }}>ログイン </h1>
              </div>

              <div className="d-flex flex-column text-center">
                <form noValidate autoComplete="off" className={'needs-validation'} onSubmit={this.onHandleSubmitLogin}>
                  <label style={{ float: 'left', color: 'black', fontSize: '16px' }}>アカウントID</label>
                  <InputForm
                    id={'txtUserName'}
                    modifyClass={' mb-10'}
                    label={`Username`}
                    type={'text'}
                    minLength="1"
                    maxLength="100"
                    required={true}
                    onChange={(e) => this.onHandleChangeInput(e, 'username')}
                    value={this.state.form.username}
                    placeholder={``}
                    msg={this.state.form.username === '' ? `ユーザーIDを入力してください` : this.state.msg.username}
                  />
                  {this.state.msg.username && <p style={{ color: 'red', fontSize: '14px' }}>{this.state.msg.username}</p>}
                  <label style={{ float: 'left', color: 'black', fontSize: '16px' }}>パスワード</label>
                  <InputForm
                    id={'txtPassword'}
                    modifyClass={' mb-20'}
                    label={'Password'}
                    defaultValue={this.state.form.password}
                    type={`password`}
                    required={true}
                    onChange={(e) => this.onHandleChangeInput(e, 'password')}
                    placeholder={``}
                    minLength="1"
                    maxLength="100"
                    msg={this.state.form.password === '' ? `パスワードを入力してください` : this.state.msg.password}
                  />
                  {this.state.msg.password && <p style={{ color: 'red', fontSize: '14px' }}>{this.state.msg.password}</p>}
                  <label style={{ float: 'left', color: 'black', fontSize: '16px', display: 'flex', cursor: 'pointer', marginBottom: '10px', marginTop: '10px' }}>
                    <input type="checkbox" style={{ zoom: 1.5, marginRight: '2px' }} defaultChecked={this.state.form.keep_login} onChange={(e) => this.onHandleChangeInput(e, 'keep_login')}></input>
                    ログインしたままにする(店長のみ)
                  </label>
                  <button
                    style={{
                      height: '50px',
                      fontSize: '22px',
                      marginBottom: '2vw',
                    }}
                    type="submit"
                    disabled={!this.state.form.username || !this.state.form.password}
                    className=" btn-success btn-block btn-round font-weight-bold"
                  >
                    ログイン
                  </button>
                  <a
                    href="/forget-password"
                    style={{
                      color: 'blue',
                      fontSize: '16px',

                      textDecoration: 'underline',
                    }}
                  >
                    パスワードを忘れた場合
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userInfo, token, actionNotify } = state.userReducer;

  return {
    userInfo,
    token,
    actionNotify,
  };
};

export default connect(mapStateToProps, { login })(LoginForm);
