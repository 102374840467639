import React, { Component } from 'react';
import CalenderForm from '../../components/calender/CalenderForm';

import ReservationTable from './ReservationTable';
import { Link } from 'react-router-dom';
import '../../../styles/components/store-reservation.scss';
// import "../../../styles/common/_card.scss"
import { connect } from 'react-redux';
import { TYPES } from '../../../actions/types';
import { getUserInfo, cleanGuessChoice, getBookingList, getBookingCountForMonth } from '../../../actions';
import _ from 'lodash';
import moment from 'moment';

class Reservationmanagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      bookingLink: '',
      actionNotify: {
        time: +new Date(),
      },
      bookingCountForMonth: props.bookingCountForMonth,
      isSelectDate: false,
    };
  }

  componentDidMount() {
    this.props.cleanGuessChoice();
    this.props.getUserInfo();
    this.props.getBookingCountForMonth({ date: moment(this.state.date).format('YYYY-MM-DD') });
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { type } = props.actionNotify;
    if (!_.isEmpty(props.actionNotify) && props.actionNotify.time > this.state.actionNotify.time) {
      switch (type) {
        case TYPES.ACTION_USER_GET_USER_INFO_SUCCESS:
          const salon_uuid = _.get(props, 'userInfo.salon_code', '');
          this.setState({ bookingLink: `/user/option?sid=${salon_uuid}` });
          break;
      }
    }
    if (props.bookingCountForMonth.ts > this.state.bookingCountForMonth.ts) {
      this.setState({
        bookingCountForMonth: props.bookingCountForMonth,
      });
    }
  }

  handleOnChangeMonth = (date) => {
    this.props.getBookingCountForMonth({ date: moment(date).format('YYYY-MM-DD') });
  };

  handleSetDateChild = (newDate) => {
    this.setState(
      {
        date: newDate,
        isSelectDate: true,
      },
      () => {
        this.props.getBookingList({ date: moment(this.state.date).format('YYYY-MM-DD') });
        this.props.getBookingCountForMonth({ date: moment(this.state.date).format('YYYY-MM-DD') });
      }
    );
  };

  handleUnSelectDate = () => {
    this.setState({
      isSelectDate: false,
    });
  };

  render() {
    return (
      <div className="w-100">
        <div className="text-dark d-flex flex-column align-items-center">
          <CalenderForm
            handleSetDateChild={this.handleSetDateChild}
            handleOnChangeMonth={this.handleOnChangeMonth}
            mode="ReservationManagement"
            date={this.state.date}
            bookingCountForMonth={this.state.bookingCountForMonth}
            isSelectDate={this.state.isSelectDate}
            handleUnSelectDate={this.handleUnSelectDate}
          />
          <div className="w-100" style={{ visibility: this.state.isSelectDate ? 'visible' : 'hidden' }}>
            <div className="d-flex justify-content-between">
              <div className="w-100">
                <ReservationTable date={this.state.date} mode="ReservationManagement" />
              </div>
            </div>
          </div>

          <div className="d-flex w-100 h-100 justify-content-center align-items-center mt-20">
            <Link style={{ color: '#007bff', textDecoration: 'underline', fontSize: '16px' }} to={this.state.bookingLink}>
              代行予約画面
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { userReducer, staffReducer } = state;
  return {
    actionNotify: userReducer.actionNotify,
    userInfo: userReducer.userInfo,
    bookingCountForMonth: staffReducer.bookingCountForMonth,
  };
}

export default connect(mapStateToProps, {
  cleanGuessChoice,
  getUserInfo,
  getBookingList,
  getBookingCountForMonth,
})(Reservationmanagement);
