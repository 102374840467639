import { TYPES } from './types';
import { staffService, userService } from '../services';
import Func from '../utils/Func';
import _ from 'lodash';

export const login = (params) => async (dispatch) => {
  try {
    const result = await userService.login(params);
    const { userInfo } = result.data;
    console.log('result: ', result);
    console.log('userInfo ==>', userInfo);
    if (userInfo) {
      dispatch({
        type: TYPES.ACTION_LOGIN_SUCCESS,
        payload: {
          token: result.data.token,
          userInfo,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_LOGIN_FAIL,
        payload: {
          message: result.data.message,
          error_code: result.data.error_code,
        },
      });
    }
  } catch (error) {
    // console.log("error", error);
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const getUserInfo =
  (params = {}) =>
  async (dispatch) => {
    const savedUserInfo = localStorage.getItem('userInfo');
    const salonToken = localStorage.getItem('salon_token');
    const isLogin = salonToken && salonToken.length > 0 ? true : false;
    try {
      if (Func.isValidJSON(savedUserInfo) && !_.isEmpty(savedUserInfo)) {
        dispatch({
          type: TYPES.ACTION_USER_GET_USER_INFO_SUCCESS,
          payload: {
            data: JSON.parse(savedUserInfo),
          },
        });

        return;
      }

      const result = isLogin ? await userService.getUserInfo() : await staffService.getGuessInfo(params);
      const { data } = isLogin ? result : result.data;
      if (_.get(data, 'status') != 'error') {
        if (_.isEmpty(data)) {
          localStorage.removeItem('salon_token');
          return;
        }
        dispatch({
          type: TYPES.ACTION_USER_GET_USER_INFO_SUCCESS,
          payload: {
            data: data,
          },
        });
      } else {
        dispatch({
          type: TYPES.ACTION_CALL_API_FAIL,
          payload: {
            message: data,
          },
        });
      }
    } catch (error) {
      // if (isLogin) {
      //   localStorage.removeItem('salon_token');
      //   window.location.href = '/login';
      // }

      dispatch({
        type: TYPES.ACTION_CALL_API_FAIL,
        payload: {
          message: error,
        },
      });
    }
  };

export const saveUserInfo = (info) => async (dispatch) => {
  try {
    if (Func.isValidJSON(info) && !_.isEmpty(info)) {
      localStorage.setItem('userInfo', JSON.stringify(info));
    }
  } catch (error) {}
};

export const cleanUserInfo = () => async (dispatch) => {
  localStorage.removeItem('userInfo');
};

export const refreshToken = () => async (dispatch) => {
  try {
    const result = await userService.refreshToken();
    const { data } = result;
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_USER_INFO_FAIL,
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const createGuessUser = (params) => async (dispatch) => {
  try {
    const result = await userService.createGuessUser(params);
    const { data } = result;
    if (data) {
      dispatch({
        type: TYPES.ACTION_CREATE_GUESS_USER_SUCCESSFULLY,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CREATE_GUESS_USER_FAIL,
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const userAction = {
  login,
  refreshToken,
  getUserInfo,
  createGuessUser,
  saveUserInfo,
  cleanUserInfo,
};
