import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class MenuAdmin extends Component {
  render() {
    return (
      <div className="d-flex flex-column align-items-center">
        <h1 style={{ color: 'black', fontSize: '26px', marginBottom: '20px',fontWeight:"bold" }}>メニュー</h1>
        <button style={{ width: '100%', fontSize: '22px', height: '80px',fontWeight:"bold",maxWidth:900,maxHeight:90 }} className="btn sp-btn btn-success btn-block btn-round mb-2">
          <Link className="d-flex text-white w-100 h-100 justify-content-center align-items-center" to={'/admin/salon-management'}>
            店舗管理
          </Link>
        </button>
        <button style={{ width: '100%', fontSize: '22px', height: '80px',fontWeight:"bold",maxWidth:900,maxHeight:90 }} className="btn sp-btn btn-success btn-block btn-round mb-2">
          <Link className="d-flex text-white w-100 h-100 justify-content-center align-items-center" to={'/admin/account-management'}>
            アカウント管理
          </Link>
        </button>
        <button style={{ width: '100%', fontSize: '22px', height: '80px',fontWeight:"bold",maxWidth:900,maxHeight:90 }} className="btn sp-btn btn-success btn-block btn-round mb-2">
          <Link className="d-flex text-white w-100 h-100 justify-content-center align-items-center" to={'/change-password'}>
            設定
          </Link>
        </button>
      </div>
    );
  }
}
